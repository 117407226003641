import React, { FC } from 'react'
import { Http4XXPage } from '@procurenetworks/procure-component-library'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ROUTES } from '../const/routes'
import { logout } from 'src/const/auth';
import { redirectToLogin } from 'src/utils/redirect-helper';

const Error: FC<RouteComponentProps<any, {}, unknown>> = ({ history }) => {
  const onSecondaryButtonClick = () => {
    history.push(ROUTES.SHOPS)
  }

  const onPrimaryButtonClick = () => {
    logout();
    redirectToLogin();

  }

  return <Http4XXPage onSecondaryButtonClick={onSecondaryButtonClick} onPrimaryButtonClick={onPrimaryButtonClick} />
}

export default withRouter(Error)
