import React from 'react'
import { Http5XXPage } from '@procurenetworks/procure-component-library'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ROUTES } from '../const/routes'
import {redirectToLogin} from "../utils/redirect-helper";

const Error5xx: React.FC<RouteComponentProps<any, {}, unknown>> = props => {
  const { history } = props
  const onSecondaryButtonClick = () => {
    history.push(ROUTES.SHOPS)
  }

  const onPrimaryButtonClick = () => {
    redirectToLogin()
  }

  return <Http5XXPage onSecondaryButtonClick={onSecondaryButtonClick} onPrimaryButtonClick={onPrimaryButtonClick} />
}

export default withRouter(Error5xx)
