import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import store from './store'
import './assets/styles/globals.css'
import BugReportingService from './bug-reporting-service'
import { LicenseInfo } from '@procurenetworks/procure-component-library';
import config from './common/config'

BugReportingService.initializeSentryClient()

LicenseInfo.setLicenseKey(config.muiPremiumLicenseKey);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
