// @flow
import React, { InputHTMLAttributes, useCallback } from 'react'
import { FieldInputProps, FormikProps } from 'formik'
import { CheckBoxContainer, CheckBoxLabel, StyledCheckbox } from './check-box-styles'

interface Props extends Omit<Omit<InputHTMLAttributes<HTMLInputElement>, 'form'>, 'type'> {
  disabled?: boolean
  field: FieldInputProps<any>
  form: FormikProps<any>
  label?: string
}

const CheckBox: React.FC<Props> = ({ field, form, label, style, disabled, ...restProps }) => {
  const _labelClickHandler = useCallback(async () => {
    if (!disabled) {
      form.setFieldTouched(field.name, true)
      form.setFieldValue(field.name, !field.value)
    }
  }, [field.name, field.value, form, disabled])

  return (
    <CheckBoxContainer>
      <StyledCheckbox
        disabled={disabled}
        {...field}
        {...restProps}
        checked={field.value}
        style={{ ...style }}
        type="checkbox"
      />
      <CheckBoxLabel disabled={!!disabled} onClick={_labelClickHandler}>
        {label}
      </CheckBoxLabel>
    </CheckBoxContainer>
  )
}

export default CheckBox
