import React from 'react'
import styled from 'styled-components'

export const InputContainer = styled.div`
  min-width: 120px;
  // display: flex;
  min-width: 120px;
  // justify-content: flex-end;
  margin-bottom: 10px;
  align-items: center;
  input.hasError {
    border: 1px solid #b94a48;
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
`

export const InputLabel = styled.div`
  min-width: fit-content;
  font-size: 13px;
  font-family: Helvetica;
  text-align: left;
  color: #797979;
  font-weight: 600;
  width: 100px;
  margin-right: 20px;
  @media (max-width: 600px) {
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
    margin-right: 0;
  }
`

export const InputLabelRequired = styled.label`
  min-width: fit-content;
  font-size: 13px;
  text-align: left;
  color: #797979;
  font-weight: 600;
  width: 100%;
  margin-right: 20px;
  &::after {
    display: inline-block;
    margin-right: -10.5px;
    margin-left: 5px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
  @media (max-width: 600px) {
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    margin-right: 0;
  }
`

interface FCProps {
  title?: string
  required?: boolean
  style?: any
}

const FormInput: React.FC<FCProps> = ({ children, title, required, style = {} }) => {
  return (
    <InputContainer style={style}>
      {required ? <InputLabelRequired>{title}</InputLabelRequired> : <InputLabel>{title}</InputLabel>}
      {children}
    </InputContainer>
  )
}

export default FormInput
