import { History } from 'history'
import { ROUTES } from '../const/routes'
import config from '../common/config'
import { httpError5XXCodes } from './httpErrorCodes'

const { unifiedHeaderConfig } = config
const { env } = unifiedHeaderConfig
const { uiUrls } = env
const { core: CORE_UI_URL } = uiUrls || {}

const LOGIN_URL = `${CORE_UI_URL}/login`

export const redirectToLoginWithContinueUrl = () => {
  window.location.href = `${LOGIN_URL}?redirectTo=${window.location.href}`
}
export const redirectToLoginWithPathUrl = (path: string) => {
  const origin = window.location.origin
  const url = `${origin}${path}`
  window.location.href = `${LOGIN_URL}?redirectTo=${url}`
}

export const redirectToLogin = () => {
  window.location.href = `${LOGIN_URL}`
}


export const handleResponseCodeRedirection = (
  router: History,
  responseCode: number | null | undefined,
  continueUrl?: string | undefined,
): boolean => {
  let shouldRedirect = false
  if (responseCode) {
    if (responseCode === 401) {
      shouldRedirect = true
    } else if (httpError5XXCodes.has(responseCode)) {
      shouldRedirect = true
      router.push({
        pathname: ROUTES.HTTP_5XX_ERROR,
        search: continueUrl ? `?redirectTo=${continueUrl}` : '',
      })
    }
  }

  return shouldRedirect
}
