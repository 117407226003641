import { useEffect } from "react";

interface PageTitle {
  title: string
}

const useTitle = (title: string) => {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title;
    return () => {
      document.title = previousTitle;
    };
  });
}


const PageTitle = (props: PageTitle) => {
  const { title } = props
  useTitle(title)
  return null;
}

export default PageTitle
