import { createSlice, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { RootState } from '../rootReducer'
import { userControler } from '../../common/clients'

// get profile
export const getProfile = createAsyncThunk('users/profile', async () => {
  const response = await userControler.getCurrentUserDetails()
  return response.data
})

type State = {
  error: any | null
  loading: boolean
  user: any | null
}

const initialState = {
  error: null,
  loading: false,
  user: null,
} as State

const userSlice = createSlice({
  initialState,
  name: 'users',
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    // profile
    builder.addCase(getProfile.pending, state => {
      state.loading = true
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.user = action.payload
      state.error = {}
      state.loading = !!action.payload
    })
    builder.addCase(getProfile.rejected, (state, action) => {
      state.user = null
      state.error = action.error
      state.loading = true
    })
  },
  reducers: {},
})

export const userSelector = (state: RootState) => state.userSlice

export default userSlice.reducer
