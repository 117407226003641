import { AffiliateLogoConfigType } from '../types/affiliate-logo-config'

class AffiliateLogoConfigFormModel {
  // public created_by: object | undefined

  public commission: string

  public domain: string

  public image: File | string | null

  public is_allow_comparison_shopping: boolean

  public is_allow_scraping: boolean

  public is_enable_chrome_extension: boolean

  public is_enabled: boolean

  public name: string

  public updatedAt: string

  // public updated_by: object | undefined

  public url: string

  public _id: string | undefined

  public merchant: string | undefined

  constructor({
    commission,
    // created_by,
    domain,
    image,
    is_allow_comparison_shopping,
    is_allow_scraping,
    is_enable_chrome_extension,
    is_enabled,
    merchant,
    name,
    updatedAt,
    // updated_by,
    url,
    _id,
  }: AffiliateLogoConfigType) {
    // this.created_by = created_by
    this.commission = commission
    this.domain = domain
    this.image = image
    this.is_allow_comparison_shopping = is_allow_comparison_shopping
    this.is_allow_scraping = is_allow_scraping
    this.is_enable_chrome_extension = is_enable_chrome_extension
    this.is_enabled = is_enabled
    this.merchant = merchant
    this.name = name
    this.updatedAt = updatedAt
    // this.updated_by = updated_by

    this.url = url
    this._id = _id
  }

  public static createFromData(affiliate: AffiliateLogoConfigType) {
    return new AffiliateLogoConfigFormModel({ ...affiliate })
  }

  public static createDefault() {
    return new AffiliateLogoConfigFormModel({
      _id: undefined,
      // created_by: undefined,
      commission: '0.00',
      domain: '',
      image: '',
      is_allow_comparison_shopping: false,
      is_allow_scraping: false,
      is_enable_chrome_extension: false,
      is_enabled: false,
      merchant: '',
      name: '',
      // updated_by: undefined,
      updatedAt: '',
      url: '',
    })
  }
}

export default AffiliateLogoConfigFormModel
