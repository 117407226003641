import React, { useState, useCallback } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Card } from '@procurenetworks/procure-component-library'
import { useSelector } from 'react-redux'
import { AffiliateMerchantsType } from '../../common/types/affiliate-merchants'
import { affiliateLogoSelector } from '../../store/slices/affiliate-logo-slice'
import { AffiliateMerchantStyle, ShopItems, ShopItem } from './affiliate-merchants-styles'
import ItemModal from '../common/ItemModal'
import ViewVendorModal from '../common/ItemModal/view-vendor-modal'

interface Props {
  data?: AffiliateMerchantsType[]
}

const renderImages = (nodes: any[]) => {
  let imgArray: any[] = []
  nodes.forEach(({ images }) => {
    if (images) {
      imgArray = imgArray.concat(images.nodes)
    }
  })
  if (imgArray.length > 0) {
    return imgArray[0].original
  }
  return ''
}

const renderVendorImage = (nodes: any[]) => {
  if (nodes?.length) {
    return nodes
  }
}

const ProductsMerchants: React.FC<Props> = ({ data }) => {
  const [open, setOpen] = useState(false)
  const [openSingleVendorModal, setSingleVendorModal] = useState(false)
  const [vendorData, setVendorData] = useState()
  // const [openVendorModal ,setOpenVendor]=useState(false)
  const [, setImagePath] = useState<string | null>('')
  const affiliateSelector = useSelector(affiliateLogoSelector)
  const [itemInfo] = useState<string | null>('')

  const openVendorModal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    modalData: any,
  ) => {
    event.stopPropagation()
    setSingleVendorModal(true)
    setVendorData(modalData)
    //  setItemInfo(data)
  }

  const renderLogo = (merchant: any) => {
    const affiliate = affiliateSelector.affiliates
    const merchantData =
      merchant &&
      affiliate.length &&
      affiliate.filter(item => item.merchant === merchant.merchantId || item.merchant === merchant.name)

    return merchantData[0]?.image ? (
      <img src={merchantData[0]?.image} alt="vendorImage" className="vendorImage" />
    ) : (
      <div className="VendorName">{merchant?.name}</div>
    )
  }

  const handleShowDialog = (image: string) => {
    if (image?.length) {
      setImagePath(image)
    }
  }

  const _handleRedirectShop = useCallback((url, event) => {
    event.stopPropagation()
    const winObject = window?.open(url, '_blank')
    if (winObject) winObject.focus()
  }, [])

  const filteredData = data?.filter(item => item.offers.nodes[0]?.merchant?.name)

  return (
    <AffiliateMerchantStyle>
      <ShopItems>
        <div className="shopCard">
          {filteredData &&
            filteredData.length > 0 &&
            filteredData.map((item: AffiliateMerchantsType, i: number) => (
              <div className="Cards" key={i}>
                <ShopItem>
                  <Card
                    title=""
                    withImage
                    imagePath={renderImages(item.offers.nodes)}
                    // title={item?.offers?.nodes[0]?.currentPrice.amount}
                    descriptionNode={
                      <div>
                        <div className="description" onClick={event => openVendorModal(event, item.offers.nodes)}>
                          {item.name}
                        </div>
                      </div>
                    }
                    target="_blank"
                    maxTitleLine={1}
                    data={item}
                    imageHeight={200}
                    onCardClick={() => handleShowDialog(renderImages(item.offers.nodes))}
                    cardActions={
                      item.offers.nodes?.length > 1 ? (
                        <button
                          className="custombutton"
                          type="button"
                          onClick={event => openVendorModal(event, item.offers.nodes)}
                        >
                          View Vendors
                        </button>
                      ) : (
                        <span
                          style={{ alignItems: 'center', display: 'flex' }}
                          onClick={event => _handleRedirectShop(item?.offers?.nodes[0]?.clickURL || '', event)}
                        >
                          {renderLogo(item.offers.nodes[0]?.merchant)}
                        </span>
                      )
                    }
                  />
                </ShopItem>
              </div>
            ))}
        </div>
        <ItemModal open={open} setOpen={(event: boolean) => setOpen(event)} data={itemInfo} />
        <ViewVendorModal
          openSingleVendorModal={openSingleVendorModal}
          renderVendorImage={renderVendorImage}
          setSingleVendorModal={(event: boolean) => setSingleVendorModal(event)}
          data={vendorData}
          renderLogo={renderLogo}
          _handleRedirectShop={_handleRedirectShop}
          handleShowDialog={handleShowDialog}
        />
      </ShopItems>
    </AffiliateMerchantStyle>
  )
}

export default ProductsMerchants
