import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(3600deg);
    }
    `

const bounce = keyframes`
from {
    margin: 0;
  }
  to {
    margin: -50px;
  }
    `

export const LoaderWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 9999;
`

export const BounceLoader = styled.div`
  transform: scale(0.2);
  position: relative;
  z-index: 2;
`

export const Loader = styled.div`
  display: flex;
  width: 180px;
  position: relative;
  top: 50px;
  flex-wrap: wrap;
  animation: ${rotate} 19s infinite linear;
`

export const CircularContainer = styled.div`
  min-width: 70px;
  height: 70px;
  position: relative;
`

const Ball = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${bounce} 0.35s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
`

export const FirstBall = styled(Ball)`
  left: 0;
  background-color: #58c9f9;
`

export const SecondBall = styled(Ball)`
  right: 0;
  background-color: #ffa500;
`

export const ThirdBall = styled(Ball)`
  bottom: 0;
  background-color: #ffa500;
`
export const ForthBall = styled(Ball)`
  bottom: 0;
  right: 0;
  background-color: #58c9f9;
`
