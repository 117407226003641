/* stylelint-disable font-family-no-missing-generic-family-keyword */
import styled, { createGlobalStyle } from 'styled-components'

export const PageBackground = styled.div`
  background-color: #f1f2f7;
  padding: 1.5rem;
  min-height: 100vh;
  box-sizing: border-box;
  /* min-height: 100vh; */
  .bg-wrap {
    background: #fff;
  }
`

export const StyledTab = styled

export const GlobalStyles = createGlobalStyle`
    * {
        font-family: 'Open Sans', sans-serif !important;
    }

`

export default {
  colors: {
    primary: '#ff9900',
    secondary: '#58c9f9',
  },
}
