/* stylelint-disable font-family-no-missing-generic-family-keyword */
import styled from 'styled-components'

export const TextInput = styled.input<any>`
  padding: 6px 12px;
  border: 1px solid #666;
  font-family: Helvetica;
  border-radius: 4px;
  color: #777;
  min-width: 300px;
  outline: none;
  max-width: 250px;
  font-size: 14px;
  height: 37px;
  box-sizing: border-box;
  ${props => props.disabled && 'cursor: not-allowed; background-color: #eee; color: #777;'}
  &:placeholder {
    font-style: italic;
    color: #ccc;
    font-size: 14px;
    font-family: Helvetica;
  }
  &:focus {
    border-color: #888;
  }
  @media (max-width: 600px) {
    min-width: 100%;
  }
`
