/* eslint-disable sort-keys */
import React, { useState, useEffect } from 'react'
import { Alert } from 'reactstrap'
import { FiCheckCircle } from 'react-icons/fi'

interface Props {
  open: boolean
  status?: string
  message?: string
  onClose?: () => void
  autoHide?: boolean
}

const Notification: React.FC<Props> = ({ open, status, message, onClose, autoHide }): JSX.Element => {
  const [visible, setVisible] = useState(true)
  const onDismiss = () => setVisible(false)
  useEffect(() => {
    if (autoHide) {
      setTimeout(() => {
        if (onClose) {
          onClose()
        }
      }, 4000)
    }
  }, [])
  return (
    <div>
      {open ? (
        <Alert
          onClose={onClose}
          color={status || 'info'}
          className={status === 'error' ? 'alertBox alertBoxError' : 'alertBox'}
          isOpen={visible}
          toggle={onDismiss}
        >
          <FiCheckCircle />
          {message}
        </Alert>
      ) : null}
    </div>
  )
}

export default Notification
