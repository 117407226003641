/* stylelint-disable font-family-no-missing-generic-family-keyword */
import { Form } from 'formik'
import styled from 'styled-components'

export const FormBody = styled.div`
  margin: 0 auto;
  display: table;
  border-collapse: separate;
  border-spacing: 30px 15px;
  @media (max-width: 400px) {
    display: block;
    padding: 20px;
  }
  .uploadImg {
    button {
      padding: 12px 20px;
      color: #ffffff;
      background: #66ccff;
      height: 34px;
      border: 0;
      border-radius: 4px;
      margin-top: 10px;
    }
    @media (max-width: 400px) {
      width: 100%;
    }
  }
`

export const FormHeading = styled.h5`
  color: #797979;
  font-weight: 500;
  font-size: 18px;
  font-family: Helvetica;
`

export const ActionStyle = styled.div`
  width: 100%;
  margin-top: 15px;
`

export const ModificationButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  button {
    margin: 0px;
    margin-left: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`

export const StyledForm = styled(Form)`
  width: fit-content;
`

export const FormStyle = styled.div`
  width: 100%;
  .selectForm {
    width: 100%;
    padding: 10px;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .MuiOutlinedInput-root {
    margin-bottom: 8px;
  }
  .MuiInputLabel-outlined {
    background: #fff !important;
  }
  .MuiOutlinedInput-input {
    padding: 9px 14px !important;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 18px) scale(1) !important;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -10px) scale(1) !important;
    font-size: 12px !important;
    padding: 0px 3px;
  }
`
export const FieldRequired = styled.span`
  color: red;
  padding-left: 2px;
`

export const TextError = styled.p`
  font-size: 13px;
  color: red;
  margin: 0;
  line-height: 19px;
`
export const AffiliateValidationError = styled.p`
  font-size: 0.75rem;
  color: #ff6666;
  margin-left: 14px;
  line-height: 19px;
  font-weight: 400;
`
export const FormConfirmDelete = styled.div`
  width: 100%;
`

export const TabHeaderModal = styled.div`
  color: #66ccff;
  border-bottom: 1px solid #eff2f7;
  padding: 0px 0px 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  svg {
    color: red;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      color: #000;
      opacity: 0.5;
    }
  }
`

export const TitleConfirmDelete = styled.h4`
  font-size: 14px;
  color: #797979;
  font-weight: bold;
  padding: 20px 30px;
`

export const ViewImage = styled.div`
  cursor: pointer;
  max-width: 100px;
  float: right;
  margin-left: 15px;
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 5px;
  background: #fff;
  padding: 0 7px;

  img {
    width: 100%;
  }
`

export const ViewImageInline = styled.div`
  position: relative;
`
export const Loading = styled.div`
  position: absolute;
  right: 30px;
  bottom: 22px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #66cc33;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 600px) {
    top: 28px;
  }
`
