import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { History } from 'history'
import { RouteComponentProps } from 'react-router-dom'
import { AffiliateLogoConfigType } from '../../../common/types/affiliate-logo-config'
import { createAffiliateLogoConfig } from '../../../store/slices/affiliate-logo-slice'
import FormAffiliateConfiguration from '../../../components/affiliate-configuration/form-affiliate-configuration'
import 'normalize.css'
import '@procurenetworks/components-unified-header/build/pn-unified-header.css'
import { userSelector } from 'src/store/slices/user-slice'
import { ROUTES } from 'src/const/routes'
import { isBoolean } from 'lodash'
import PageTitle from 'src/components/PageTitle'
import { PAGE_TITLE } from 'src/common/constants'

interface Props {
  data?: any
  history: History
}

const TitlePage = styled.h3`
  color: #66ccff;
  padding: 15px 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid rgb(239, 242, 247);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
`

const AddAffiliateConfiguration: React.FC<RouteComponentProps<undefined, {}, Props>> = ({ history }) => {
  const dispatch = useDispatch()

  const userDetail = useSelector(userSelector)
  const isSuperAdmin = userDetail.user?.currentUser.isSuperAdmin

  useEffect(() => {
    if (isBoolean(isSuperAdmin) && !isSuperAdmin) history.push(ROUTES.SUPPLIERS)
  }, [isSuperAdmin])

  const handleCreateAffiliate = useCallback(
    (affiliate: AffiliateLogoConfigType) => {
      dispatch(createAffiliateLogoConfig(affiliate))
      history.push('/admin/affiliate-configuration')
    },
    [dispatch],
  )

  return (
    <div className="bg-white bg-wrap">
      <PageTitle title={PAGE_TITLE.ADD_AFFILIATE_CONFIGURATION}/>
      <TitlePage>New Affiliate Configuration</TitlePage>
      <FormAffiliateConfiguration onSave={handleCreateAffiliate} />
    </div>
  )
}

export default AddAffiliateConfiguration
