import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
`

export const AffiliateStyle = styled.div`
  width: 100%;
  table {
    /* border: 1px solid #ddd; */
    thead {
      tr {
        border-bottom: 2px solid #ddd;
        th {
          padding: 8px 16px;
          border: 1px solid #ddd;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 8px 16px;
          border-top: none;
          color: #777;
          border: 1px solid #ddd;
          a {
            color: #777;
            text-decoration: underline;
          }
        }
      }
    }
  }
`

export const CheckedStyle = styled.div`
  color: #66cc33;
`

export const UnCheckedStyle = styled.div`
  color: #ff6666;
`

export const LogoStyle = styled.div`
  cursor: pointer;
  max-width: 120px;
  display: flex;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
  }
`
