import config from '../config'
import { NetworkService } from '../services'
import { isServer } from '../utils/platform-utils'

interface UserController {
  getProfile: () => void
}

class UserServiceClient implements UserController {
  private readonly _networkService: NetworkService

  private readonly _basePath: string

  constructor(baseUri: string | undefined, basePath: string) {
    this._networkService = new NetworkService(baseUri)
    this._basePath = basePath
  }

  private defaultErrorHandler = (error: any) => {
    if (error.redirect) {
      if (!isServer) {
        // window.location.assign(error.redirectionUrl)
      }
    }
    throw error
  }

  // supplier controllers
  public getProfile() {
    return this._networkService.get({
      errorCallback: this.defaultErrorHandler,
      restConfig: {
        withCredentials: true,
      },
      url: `${this._basePath}/users/profile`,
    })
  }

  public getCurrentUserDetails() {
    return this._networkService.post({
      errorCallback: this.defaultErrorHandler,
      restConfig: {
        withCredentials: true
      },
      headers: {
        "accept": "application/json, multipart/mixed",
        "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
        "content-type": "application/json",
      },
      body: "{\"query\":\"query userData {\\n  currentUser {\\n    id\\n    isSuperAdmin\\n    name\\n    workspacePermissions {\\n      action\\n      subject\\n\\n    }\\n    profilePicture\\n    tenantId\\n    \\n  }\\n  tenants {\\n    edges {\\n      node {\\n        id\\n        name\\n      }\\n    }\\n  }\\n}\\n\\n\\n#  62ce8db2e8bccab3cc3d4c59\\n\\n\",\"variables\":null,\"operationName\":\"userData\"}",
      url: `${this._basePath}`,
    })
  }
}

const baseUri = config.unifiedHeaderConfig.env.apiUrls.api

export default new UserServiceClient(baseUri, '/api/graphql')
