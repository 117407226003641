import React from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ROUTES } from '../const/routes'
import Suppliers from '../pages/suppliers/suppliers'
import Affiliates from '../pages/admin/affiliate-configuration'
import AddAffiliateConfiguration from '../pages/admin/affiliate-configuration/add'
import editAffiliateConfiguration from '../pages/admin/affiliate-configuration/edit'
import NotFound from '../pages/NotFound'
import Error5xx from '../pages/error-5xx'
import UnifiedHeaderWrapper from "../components/common/unified-header";
import RouteWithLayout from "./RouteWithLayout";

const { SHOPS, SUPPLIERS, AFFILIATE, ADD_AFFILIATE, EDIT_AFFILIATE, HTTP_4XX_ERROR, HTTP_5XX_ERROR } = ROUTES
// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route)

const AppRoutes = () => {
  return (
    <Switch>

      <RouteWithLayout
        layout={UnifiedHeaderWrapper}
        component={Suppliers}
        path={SHOPS}
        exact
      />

      <RouteWithLayout
        layout={UnifiedHeaderWrapper}
        component={Affiliates}
        path={AFFILIATE}
        exact
      />

      <RouteWithLayout
        layout={UnifiedHeaderWrapper}
        component={AddAffiliateConfiguration}
        path={ADD_AFFILIATE}
        exact
      />

      <RouteWithLayout
        layout={UnifiedHeaderWrapper}
        component={editAffiliateConfiguration}
        path={EDIT_AFFILIATE}
        exact
      />

      <SentryRoute exact path={HTTP_5XX_ERROR} component={Error5xx} />
      <SentryRoute exact path={HTTP_4XX_ERROR} component={NotFound} />

      <Redirect exact from="/" to={SHOPS}/>
      <Redirect exact from={SHOPS} to={SHOPS}/>
      <Redirect exact from={SUPPLIERS} to={SHOPS}/>
      <Redirect exact from="*" to={HTTP_4XX_ERROR}/>
    </Switch>
  )
}

export default withRouter(AppRoutes)
