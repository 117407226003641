import config from "../common/config";
import Cookie from '../utils/cookie'

const { unifiedHeaderConfig } = config
const { env } = unifiedHeaderConfig || {}
const { cookieKeys } = env || {}


export const SESSION_KEY = config.unifiedHeaderConfig.env.cookieKeys.accessToken || ''

export const logout = () => {
  if(cookieKeys) {
    Cookie.remove(cookieKeys.id, { domain: cookieKeys.domain });
    Cookie.remove(cookieKeys.tenant, { domain: cookieKeys.domain });
    Cookie.remove(cookieKeys.accessToken, { domain: cookieKeys.domain });
    Cookie.remove(cookieKeys.refreshToken, { domain: cookieKeys.domain });
  }
}
