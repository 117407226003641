import React, { useEffect, useState } from 'react'
import { FileUploader, ProfileUploaderFileChangeValue } from '@procurenetworks/procure-component-library'

interface Props {
  name: string
  innerRef: any
  files: any
  setValue: any
  clearError?: any
}

const UploadImage: React.FC<Props> = ({ name, innerRef, files, setValue, clearError }) => {
  const [fileList, setFileList] = useState<any>([])
  const [fileLoading, setFileLoading] = useState(false)
  useEffect(() => {
    innerRef({ name })
  }, [])
  useEffect(() => {
    setValue(name, files)
    if (files && typeof files === 'string') {
      setFileList([
        {
          id: `${Date.now()}${0}`,
          src: files,
        },
      ])
    }
  }, [files])

  const onFilesSelect = (selectedFiles: ProfileUploaderFileChangeValue[]) => {
    setFileLoading(true)
    setTimeout(() => {
      setFileList([
        ...selectedFiles.map((file: ProfileUploaderFileChangeValue, index: number) => {
          return {
            id: `${Date.now()}${index}`,
            src: file.base64String,
          }
        }),
      ])
      setFileLoading(false)
    }, 1000)
  }

  const onDeleteSelectedImage = (imageId: any) => {
    const newFileList = fileList.filter((file: { id: any }) => file.id !== imageId)
    setFileList(newFileList)
  }

  return (
    <FileUploader
      id="file-uploader-1"
      fileList={fileList}
      loading={fileLoading}
      onFilesSelect={filesInfo => {
        if (filesInfo.length > 0 && filesInfo[0].file) {
          setValue(name, filesInfo[0].file, { shouldDirty: true })
        }
        if (clearError) {
          clearError()
        }
        onFilesSelect(filesInfo)
      }}
      onDeleteSelectedImage={onDeleteSelectedImage}
      accept="image/*"
    />
  )
}

export default UploadImage
