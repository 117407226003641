import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { History } from 'history'
import { AffiliateLogoConfigType } from '../../../common/types/affiliate-logo-config'
import { MainLoader } from '../../../components/common/custom-loader'

import {
  getAffiliateLogoConfigById,
  affiliateLogoSelector,
  updateAffiliateLogoConfigWithId,
  deleteAffiliateLogoConfigWithId,
  getAllAffiliateLogoConfig,
} from '../../../store/slices/affiliate-logo-slice'

import FormAffiliateConfiguration from '../../../components/affiliate-configuration/form-affiliate-configuration'

import 'normalize.css'
import '@procurenetworks/components-unified-header/build/pn-unified-header.css'
import { userSelector } from 'src/store/slices/user-slice'
import { ROUTES } from 'src/const/routes'
import isBoolean from 'lodash/isBoolean'
import PageTitle from 'src/components/PageTitle'
import { PAGE_TITLE } from 'src/common/constants'

interface Props {
  affiliate?: AffiliateLogoConfigType
  history: History
}

const TitlePage = styled.h3`
  color: #66ccff;
  padding: 15px 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid rgb(239, 242, 247);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
`

const EditAffiliateConfiguration: React.FC<any> = ({ history }: Props) => {
  const state: any = history?.location?.state
  const slug: any = state?.row_id

  const dispatch = useDispatch()
  const affiliateSelector = useSelector(affiliateLogoSelector)

  const userDetail = useSelector(userSelector)
  const isSuperAdmin = userDetail.user?.currentUser.isSuperAdmin

  useEffect(() => {
    if (isBoolean(isSuperAdmin) && !isSuperAdmin) history.push(ROUTES.SUPPLIERS)
  }, [isSuperAdmin])

  useEffect(() => {
    if (slug) {
      dispatch(getAffiliateLogoConfigById(slug))
    }
  }, [slug, dispatch])

  const handleUpdateAffiliate = useCallback(
    async (affiliate: AffiliateLogoConfigType) => {
      dispatch(updateAffiliateLogoConfigWithId(affiliate))
      history.push('/admin/affiliate-configuration')
    },
    [dispatch],
  )

  const handleDeleteAffiliate = useCallback(
    (id: string) => {
      dispatch(deleteAffiliateLogoConfigWithId(id))
    },
    [dispatch],
  )

  const handleReloadAffiliates = useCallback(() => {
    dispatch(getAllAffiliateLogoConfig({ name: '' }))
  }, [dispatch])

  return (
    <>
      {affiliateSelector.loading ? (
        <MainLoader active />
      ) : (
        <div className="bg-white bg-wrap">
          <PageTitle title={PAGE_TITLE.EDIT_AFFILIATE_CONFIGURATION}/>
          <TitlePage>Edit Affiliate Configuration</TitlePage>
          {affiliateSelector.affiliate && (
            <FormAffiliateConfiguration
              affiliate={affiliateSelector.affiliate}
              slug={slug}
              onSave={handleUpdateAffiliate}
              onDelete={handleDeleteAffiliate}
              onReloadList={handleReloadAffiliates}
            />
          )}
        </div>
      )}
    </>
  )
}

export default EditAffiliateConfiguration
