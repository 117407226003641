/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch

export default store
