import React, { useMemo } from 'react'
import ReactModal from 'react-modal'

function _getStyles(style: ReactModal.Styles | undefined): ReactModal.Styles {
  return {
    content: {
      bottom: 'auto',
      left: 'auto',
      maxHeight: '90%',
      maxWidth: '90%',
      padding: '2%',
      position: 'relative',
      right: 'auto',
      top: 'auto',
      ...style?.content,
    },
    overlay: {
      alignItems: 'center',
      backgroundColor: '#000000a6',
      display: 'flex',
      justifyContent: 'center',
      zIndex: 1000,
      ...style?.overlay,
    },
  }
}

const Modal: React.FC<ReactModal.Props> = ({ children, style, ...restProps }) => {
  const modalStyles: ReactModal.Styles = useMemo(() => {
    return _getStyles(style)
  }, [style])

  return (
    <ReactModal style={modalStyles} shouldCloseOnEsc shouldCloseOnOverlayClick ariaHideApp={false} {...restProps}>
      {children}
    </ReactModal>
  )
}

export default Modal
