import React from 'react'
import { useForm, UseFormMethods, SubmitHandler, FieldValues } from 'react-hook-form'

type FormProps<TFormValues extends FieldValues> = {
  onSubmit: SubmitHandler<TFormValues>
  children: (methods: UseFormMethods<TFormValues>) => React.ReactNode
  defaultValues?: any
  resolver?: any
  mode?: 'onBlur' | 'onChange' | 'onSubmit'
}

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
  defaultValues = {},
  resolver,
  mode = 'onChange',
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>({ defaultValues, reValidateMode: 'onChange', resolver, mode })
  return <form onSubmit={methods.handleSubmit(onSubmit)}>{children(methods)}</form>
}

export default Form
