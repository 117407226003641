import styled from 'styled-components'

export const AffiliateMerchantStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 24px;
`

export const Title = styled.h3`
  font-size: 13px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  color: #797979;
`

export const ShopItems = styled.div`
  width: 100%;
  .shopCard {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .Cards {
    padding: 24px;
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    @media (max-width: 1024px) {
      .shopCard {
        justify-content: center;
      }
    }
    @media (max-width: 1024px) {
      flex-grow: 1;
      width: 50%;
      flex-basis: 50%;
      max-width: 50%;
    }
    @media (max-width: 600px) {
      flex-grow: 1;
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`
export const ShopItem = styled.div`
  width: 100%;
  height: 162px;
  display: inline-block;
  height: auto;
  box-sizing: border-box;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 10px;
  img {
    height: 40px;
    padding: 4px 10px;
    /* width: 110px !important; */
    max-width: 100%;
    margin: 0 auto;
    outline: none;
    display: block !important;
  }
  .bannerimg {
    @media only screen and (max-width: 1200px) {
      max-width: 80%;
      padding: 0;
    }
    @media only screen and (max-width: 980px) {
      max-width: 70%;
    }
    @media only screen and (max-width: 900px) {
      max-width: 60%;
    }
    @media only screen and (max-width: 850px) {
      max-width: 45%;
    }
    @media only screen and (max-width: 599px) {
      max-width: 100%;
    }
    .action-button {
      margin: 6px auto;
    }
    .MuiCardMedia-root {
      margin: 20px auto !important;
      border-bottom: none !important;
      max-width: 66% !important;
      max-height: 23% !important;
    }
  }
`

export const ShopNewLogo = styled.a`
  position: relative;
  /* display: inline-block; */
  width: 100%;
  /* height: 180px; */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .slider-arrow {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .arrow-btn {
    top: 35%;
    left: -16px;
    z-index: 1;
  }
  .next {
    float: right;
  }

  div {
    .slick-dots {
      bottom: 0;
    }
    .slick-dots li button:before {
      font-family: 'slick';
      font-size: 20px;
      line-height: 20px;
    }
  }
  @media only screen and (min-width: 868px) and (max-width: 1200px) {
    height: 187px;
    p {
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
  @media only screen and (max-width: 867px) {
    height: 115px;
    p {
      height: 100%;
      text-align: center;
    }
  }
  @media only screen and (max-width: 600px) {
    height: unset;
    p {
      height: unset;
    }
  }
`

export const ShopLogo = styled.a`
  /* display: inline-block; */
  width: 100%;
  height: 180px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 376px) {
    height: 100px;
  }
  @media (min-width: 425px) {
    height: 125px;
  }
  @media (min-width: 767px) {
    height: 124px;
  }
  @media (min-width: 991px) {
    height: 155px;
  }
  @media (min-width: 1024px) {
    height: 93px;
  }
  @media (min-width: 1440px) {
    height: 162px;
  }
`
export const ShopLogoNewImage = styled.img`
  object-fit: contain;
  width: 173px !important;
  /* height: 180px; */
  /* height: 180px !important; */
  @media only screen and (min-width: 868px) and (max-width: 1200px) {
    height: 187px;
    img {
      height: 100%;
    }
  }
  @media only screen and (max-width: 867px) {
    height: 115px;

    img {
      height: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    height: unset;
    img {
      height: 100%;
    }
  }
`

export const ShopLogoImage = styled.img<any>`
  object-fit: contain;

  /* height: 180px !important; */
`
export const NoImgNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 191px;
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  @media only screen and (max-width: 868px) {
    height: 100%;
  }
`
export const NoImageFound = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 10px auto;
`

export const ProductImage = styled.a`
  display: inline-block;
  width: 100%;
  height: 180px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  @media (min-width: 425px) {
    height: 125px;
  }
  @media (min-width: 767px) {
    height: 182px;
  }
  @media (min-width: 991px) {
    height: 155px;
  }
  @media (min-width: 1024px) {
    height: 164px;
  }
  @media (min-width: 1440px) {
    height: 268px;
  }
`

export const ShopTitle = styled.a`
  text-align: center;
  font-weight: normal;
  line-height: 1.5;
  font-size: 14px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  margin: 10px 0 5px;
  &:hover {
    cursor: pointer;
    color: #66ccff;
  }
`

export const SaleLabel = styled.p`
  font-size: 13px;
  text-align: center;
  margin: 0;
  color: #888;
`

export const AmountLabel = styled.h3`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 0;
  color: #000;
`

export const MerchantLabel = styled.p`
  text-align: center;
  font-size: 14px;
  margin: 0;
  line-height: 19px;
  text-transform: uppercase;
`

export const CompanyLogo = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  span {
    @media only screen and (max-width: 1200px) {
      width: 60% !important;
    }
    @media only screen and (max-width: 1024px) {
      width: 70% !important;
    }
    @media only screen and (max-width: 900px) {
      width: 80% !important;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`

export const CompanyName = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  min-height: 98px;
  p {
    word-break: break-all;
  }
`
