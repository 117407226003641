import React, { useState, useCallback } from 'react'
import { FaTimesCircle, FaSearch } from 'react-icons/fa'
import { SearchLabel, DisplayResults, SearchStyle, SearchBoxInline, SearchIconStyle } from './index-styles'
interface Props {
  rowsPerPage: number
  total: number
  label?: string
  currentTotal: number
  hideResults?: boolean
  showIcon?: boolean
  autoFocus?: boolean
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent) => void
  onSearch?: () => void
  page?: number
  totalLength: number | undefined
}

const SearchBox: React.FC<Props> = ({
  rowsPerPage,
  total,
  currentTotal,
  onChange,
  label,
  hideResults,
  placeholder,
  showIcon,
  onSearch,
  page,
  totalLength,
  autoFocus,
}) => {
  const [value, setValue] = useState('')
  const startIndex = page && total ? (page - 1) * rowsPerPage + 1 : 0
  const endIndex = startIndex + rowsPerPage > currentTotal ? currentTotal : startIndex + rowsPerPage - 1

  const _handleChange = useCallback(
    (
      event:
        | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      const inputValue = event as any
      setValue(inputValue.target.value)
      onChange(inputValue)
    },
    [setValue, onChange],
  )

  const _handleClear = useCallback(
    (event: React.MouseEvent) => {
      setValue('')
      onChange(event)
    },
    [setValue, onChange],
  )

  return (
    <SearchStyle>
      <SearchLabel>{label && label ? label : 'Search'}</SearchLabel>
      <div className="flex-container">
        <SearchBoxInline>
          <input
            className={showIcon ? 'viewSearchIcon searchField' : 'searchField'}
            onChange={_handleChange}
            placeholder={placeholder || ''}
            value={value}
            autoFocus={autoFocus}
          />
          {value ? (
            <button onClick={_handleClear} type="button" className="clearButton">
              <FaTimesCircle size={18} />
            </button>
          ) : (
            <></>
          )}
        </SearchBoxInline>
        {showIcon && (
          <SearchIconStyle className="search-icon" onClick={onSearch}>
            <FaSearch />
          </SearchIconStyle>
        )}
      </div>

      {!hideResults && (
        <DisplayResults>
          Showing {startIndex} to {endIndex} of {total || totalLength || 0} entries
        </DisplayResults>
      )}
    </SearchStyle>
  )
}

export default SearchBox
