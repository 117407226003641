import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { mixed, object, string } from 'yup'

import { Select, Popover, TextField } from '@procurenetworks/procure-component-library'
import { useLazyQuery } from '@apollo/client'
import { withRouter } from 'react-router-dom'
import { affiliateLogoSelector } from '../../store/slices/affiliate-logo-slice'
import AffiliateLogoConfigFormModel from '../../common/models/affiliate-logo-config'
import { AffiliateLogoConfigType } from '../../common/types/affiliate-logo-config'

import { GET_MERCHANTS } from '../../common/queries'
import FormInput from '../common/form/inline-form'
import Modal from '../common/modal'
import Form from '../common/form'
import { PNSwitch, UploadImage } from '../common/form/form-inputs'
import { GreenButton, GreyButton, RedButton } from '../common/button'
import Notification from '../common/notification'
import {
  FormBody,
  ModificationButtonContainer,
  ActionStyle,
  FormStyle,
  FormConfirmDelete,
  TabHeaderModal,
  TitleConfirmDelete,
  Loading,
  FieldRequired,
  AffiliateValidationError,
} from './form-styles'
import { ROUTES } from 'src/const/routes'

interface Props {
  affiliate?: AffiliateLogoConfigType | undefined
  slug?: any | undefined | null
  onSave: (parameters: AffiliateLogoConfigType) => void
  onDelete?: (parameters: string) => void
  onReloadList?: () => void
  history: any
}
const FormAffiliateConfiguration: React.FC<any> = ({
  affiliate,
  slug,
  onSave,
  onDelete,
  onReloadList,
  history,
}: Props) => {
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  // notification
  const [openNotify, setOpenNotify] = useState(false)
  const [message, setMessage] = useState<string | undefined>()
  const [status, setStatus] = useState<string | undefined>()
  const [merchant, setMerchant] = useState<string>(affiliate?.merchant ? affiliate?.merchant : '')
  const [getMerchants, { loading, data }] = useLazyQuery(GET_MERCHANTS)
  const [merchantOptions, setMerchantsOption] = useState([{ label: '', value: '' }])
  const [affiliateForm, setAffiliateForm] = useState<Partial<AffiliateLogoConfigFormModel>>()
  const affiliateSelector = useSelector(affiliateLogoSelector)

  const _initialValue = useMemo(
    () =>
      affiliate ? AffiliateLogoConfigFormModel.createFromData(affiliate) : AffiliateLogoConfigFormModel.createDefault(),
    [affiliate],
  )

  useEffect(() => {
    if (_initialValue) {
      setAffiliateForm({ ..._initialValue })
    }
  }, [_initialValue])

  const _handleBack = useCallback(() => {
    history.push(ROUTES.AFFILIATE)
  }, [])

  useEffect(() => {
    if (affiliateSelector.notification.status) {
      setMessage(affiliateSelector.notification.message)
      setStatus(affiliateSelector.notification.status)
    }
    if(message){
      setOpenNotify(true)
      setMessage('')
    }
  }, [affiliateSelector.notification, setMessage, setOpenNotify, setStatus])

  useEffect(() => {
    const dataArray =
      data && data.query && data.query.length > 0
        ? data.query.map((item: string) => {
            return { label: item, value: item }
          })
        : [{ label: '', value: '' }]

    setMerchantsOption(dataArray)
  }, [data])

  useEffect(() => {
    getMerchants()
  }, [getMerchants])

  const _handleConfirmDelete = useCallback(() => {
    setIsConfirmDelete(!isConfirmDelete)
  }, [isConfirmDelete, setIsConfirmDelete])

  const _handleDelete = useCallback(async () => {
    if (slug && onDelete) {
      setIsLoadingDelete(true)
      await onDelete(slug)
      setIsConfirmDelete(!isConfirmDelete)
      if (onReloadList) {
        await onReloadList()
        _handleBack()
      }
    }
  }, [isConfirmDelete, setIsConfirmDelete, onDelete, slug, onReloadList])

  const _handleSubmit = useCallback(
    async (values: any) => {
      values = {
        ...values,
        commission: parseFloat(values.commission).toFixed(2),
        name: values.name.trim(),
      }
      try {
        if (values) {
          await onSave({ ..._initialValue, ...values, merchant })
        }
      } catch (error) {
        console.log('error:', error)
      }
    },
    [onSave, _initialValue, merchant],
  )

  const _handleCloseNotify = useCallback(() => {
    setOpenNotify(false)
  }, [setOpenNotify])
  const containsSpace = (string: any) => /^\S+( +\S+)*$/g.test(string.trim())
  const schema = object().shape({
    image: mixed()
      .transform(file => (!file ? undefined : file))
      .required('Affiliate logo is required.'),
    name: string()
      .required('Name is a required field.')
      .test('name', 'Empty string is not allowed in the supplier name', value => containsSpace(value)),
    url: string()
      .required('Affiliate URL is required field.')
      .matches(
        /(http(s)?:\/\/.)?(www\.)?[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/g,
        'Please enter valid affiliate URL',
      ),
  })

  return (
    <>
      {openNotify ? (
        <Notification status={status} open={openNotify} message={message} onClose={_handleCloseNotify} autoHide />
      ) : (
        <></>
      )}
      <Form<AffiliateLogoConfigType>
        onSubmit={_handleSubmit}
        defaultValues={_initialValue}
        resolver={yupResolver(schema)}
        mode="onChange"
      >
        {({ register, control, watch, setValue, clearErrors, formState: { errors } }) => {
          return (
            <FormStyle>
              <FormBody>
                <FormInput>
                  <TextField
                    label="Supplier name"
                    labelNode={() => {
                      return (
                        <span>
                          Supplier name <FieldRequired>*</FieldRequired>
                        </span>
                      )
                    }}
                    name="name"
                    onChange={(event: any) => {
                      setValue('name', event.target.value)
                      setAffiliateForm({ ...affiliateForm, name: event.target.value })
                      clearErrors('name')
                    }}
                    value={affiliateForm?.name}
                    getInputReference={() => register('name')}
                    className={errors.name && 'hasError'}
                    error={!!errors.name?.message}
                    errorMessage={errors.name?.message}
                    autoFocus
                  />
                </FormInput>
                <FormInput>
                  <TextField
                    label="Affiliate URL"
                    labelNode={() => {
                      return (
                        <span>
                          Affiliate URL <FieldRequired>*</FieldRequired>
                        </span>
                      )
                    }}
                    name="url"
                    onChange={(event: any) => {
                      setValue('url', event.target.value)
                      setAffiliateForm({ ...affiliateForm, url: event.target.value })
                      clearErrors('url')
                    }}
                    value={affiliateForm?.url}
                    getInputReference={() => register('url')}
                    className={errors.name && 'hasError'}
                    error={!!errors?.url}
                    errorMessage={errors.url?.message}
                  />
                </FormInput>
                <FormInput>
                  <TextField
                    label="Domain"
                    id="domain"
                    name="domain"
                    onChange={(event: any) => {
                      setValue('domain', event.target.value)
                      setAffiliateForm({ ...affiliateForm, domain: event.target.value })
                    }}
                    value={affiliateForm?.domain}
                    getInputReference={() => register('domain')}
                    className={errors.name && 'hasError'}
                  />
                </FormInput>
                <FormInput>
                  <TextField
                    label="Commission"
                    id="commission"
                    name="commission"
                    getInputReference={() => register('commission')}
                    onChange={(event: any) => {
                      setValue('commission', event.target.value)
                      setAffiliateForm({ ...affiliateForm, commission: event.target.value })
                    }}
                    value={affiliateForm?.commission}
                    className={errors.name && 'hasError'}
                  />
                </FormInput>
                <FormInput title="Affiliate Logo" required>
                  <div className="uploadImg">
                    <UploadImage
                      name="image"
                      innerRef={register}
                      files={watch('image')}
                      setValue={setValue}
                      clearError={clearErrors}
                    />
                    {!!errors?.image && <AffiliateValidationError>{errors.image.message}</AffiliateValidationError>}
                  </div>
                </FormInput>
                <FormInput title="Enabled">
                  <div style={{ flex: 1 }}>
                    <PNSwitch name="is_enabled" control={control} />
                  </div>
                </FormInput>
                <FormInput style={{ position: 'relative' }}>
                  <Popover
                    id="popover"
                    width={220}
                    open={false}
                    element={() => (
                      <Select
                        value={merchant}
                        disabled={loading}
                        selectClassName="selectForm"
                        onChange={(event: any) => {
                          setMerchant(event.target.value)
                        }}
                        label="Merchant"
                        options={merchantOptions}
                      />
                    )}
                  />
                  {loading ? <Loading /> : <></>}
                </FormInput>
                <FormInput>
                  <ActionStyle>
                    <ModificationButtonContainer>
                      {slug ? (
                        <>
                          <GreenButton type="submit">Save</GreenButton>
                          <RedButton type="button" onClick={_handleConfirmDelete}>
                            Delete
                          </RedButton>
                          <GreyButton type="reset" onClick={_handleBack}>
                            Cancel
                          </GreyButton>
                        </>
                      ) : (
                        <>
                          <GreenButton type="submit">Add</GreenButton>
                          <GreyButton type="reset" onClick={() => _handleBack()}>
                            Cancel
                          </GreyButton>
                        </>
                      )}
                    </ModificationButtonContainer>
                  </ActionStyle>
                </FormInput>
              </FormBody>
            </FormStyle>
          )
        }}
      </Form>

      <Modal isOpen={isConfirmDelete} onRequestClose={_handleConfirmDelete}>
        <TabHeaderModal>
          <span>Delete!</span> <FaTimes onClick={_handleConfirmDelete} />
        </TabHeaderModal>
        <FormConfirmDelete>
          <TitleConfirmDelete>Are you sure you want to delete this affiliate?</TitleConfirmDelete>
          <div>
            <ModificationButtonContainer>
              <GreenButton isLoading={isLoadingDelete} type="submit" onClick={_handleDelete}>
                Yes
              </GreenButton>
              <RedButton type="reset" onClick={_handleConfirmDelete}>
                No
              </RedButton>
            </ModificationButtonContainer>
          </div>
        </FormConfirmDelete>
      </Modal>
    </>
  )
}

export default withRouter(FormAffiliateConfiguration)
