import { nodeEnvironment, development } from '../utils/environment-utils'

const {
  REACT_APP_BASE_PATH,
  REACT_APP_SHOP_SERVICE_BASE_URL,
  REACT_APP_CONTACT_API_URL,
  REACT_APP_CORE_API_URL,
  REACT_APP_INVENTORY_API_URL,
  REACT_APP_ORDER_API_URL,
  REACT_APP_SHIP_API_URL,
  REACT_APP_SHOP_API_URL,
  REACT_APP_CONTACT_UI_URL,
  REACT_APP_CORE_UI_URL,
  REACT_APP_INVENTORY_UI_URL,
  REACT_APP_ORDER_UI_URL,
  REACT_APP_SHOP_UI_URL,
  REACT_APP_SHIP_UI_URL,
  REACT_APP_DEPLOYMENT_ENVIRONMENT,
  REACT_APP_SENTRY_DSN,
  REACT_APP_HOOVER_URL,
  REACT_APP_PN_CORE_SESSION,
  REACT_APP_COOKIE_ACCESS_TOKEN_KEY,
  REACT_APP_COOKIE_REFRESH_TOKEN_KEY,
  REACT_APP_COOKIE_ID_KEY,
  REACT_APP_COOKIE_TENANT_KEY,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_API_URL,
  REACT_APP_MUI_LICENSE_KEY,
} = process.env

interface AppConfig {
  basePath: string | undefined
  backendUrl: string | undefined
  unifiedHeaderConfig: {
    currentApp: string
    env: {
      apiUrls: {
        api: string | undefined
        contact: string | undefined
        core: string | undefined
        inventory: string | undefined
        order: string | undefined
        ship: string | undefined
        shop: string | undefined
        hoover: string | undefined
      }
      uiUrls: {
        contact: string | undefined
        core: string | undefined
        inventory: string | undefined
        order: string | undefined
        ship: string | undefined
        shop: string | undefined
      },
      cookieKeys: {
        accessToken: string
        refreshToken: string
        id: string
        tenant: string
        domain: string
      },
    }
  }
  dev: boolean
  environment: string | undefined
  nodeEnvironment: 'development' | 'production'
  deploymentEnvironment: string | undefined
  sentryDSN: string | undefined
  pnCoreSession: string | undefined
  muiPremiumLicenseKey: string
}

const config: AppConfig = {
  backendUrl: REACT_APP_SHOP_SERVICE_BASE_URL,
  basePath: REACT_APP_BASE_PATH,
  dev: development,
  environment: REACT_APP_DEPLOYMENT_ENVIRONMENT,
  nodeEnvironment,
  pnCoreSession: REACT_APP_PN_CORE_SESSION,
  unifiedHeaderConfig: {
    currentApp: 'shop',
    env: {
      apiUrls: {
        api: REACT_APP_API_URL,
        contact: REACT_APP_CONTACT_API_URL,
        core: REACT_APP_CORE_API_URL,
        inventory: REACT_APP_INVENTORY_API_URL,
        order: REACT_APP_ORDER_API_URL,
        ship: REACT_APP_SHIP_API_URL,
        shop: REACT_APP_SHOP_API_URL,
        hoover: REACT_APP_HOOVER_URL,
      },
      uiUrls: {
        contact: REACT_APP_CONTACT_UI_URL,
        core: REACT_APP_CORE_UI_URL,
        inventory: REACT_APP_INVENTORY_UI_URL,
        order: REACT_APP_ORDER_UI_URL,
        shop: REACT_APP_SHOP_UI_URL,
        ship: REACT_APP_SHIP_UI_URL,
      },
      cookieKeys: {
        accessToken: REACT_APP_COOKIE_ACCESS_TOKEN_KEY || '',
        refreshToken: REACT_APP_COOKIE_REFRESH_TOKEN_KEY || '',
        id: REACT_APP_COOKIE_ID_KEY || '',
        domain: REACT_APP_COOKIE_DOMAIN || '',
        tenant: REACT_APP_COOKIE_TENANT_KEY || '',
      },
    },
  },
  deploymentEnvironment: REACT_APP_DEPLOYMENT_ENVIRONMENT,
  sentryDSN: REACT_APP_SENTRY_DSN,
  muiPremiumLicenseKey: REACT_APP_MUI_LICENSE_KEY || '',
}

export default config
