import React from 'react'
import { Controller } from 'react-hook-form'
import Switch from '../../../switchToggle/switch'
const PNSwitch = (props: any): any => (
  <Controller
    defaultValue={false}
    rules={{ required: true }}
    render={({ onChange, value }): any => <Switch onChange={event => onChange(event.target.checked)} checked={value} />}
    {...props}
  />
)

export default PNSwitch
