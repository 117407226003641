import React from 'react'
import { TextInput } from './text-input-styles'

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, innerReference) => (
  <TextInput ref={innerReference} {...props} />
))

Input.displayName = 'Input'

export default Input
