import React, { useCallback } from 'react'

import { AffiliateLogoConfigType } from '../../common/types/affiliate-logo-config'

import {
  AffiliateMerchantStyle,
  ShopItems,
  ShopItem,
  ShopTitle,
  ShopLogo,
  SaleLabel,
  ShopLogoImage,
  NoImageFound,
} from './affiliate-merchants-styles'

interface Props {
  data?: AffiliateLogoConfigType[]
}

const AffiliateMerchants: React.FC<Props> = ({ data }) => {
  const _handleRedirectShop = useCallback((url: string) => {
    let _url = url
    if (!url?.startsWith('http')) {
      _url = `http://${url}`
    }
    window?.open(_url, '_blank')
  }, [])

  return (
    <AffiliateMerchantStyle>
      <ShopItems>
        <div className="shopCard">
          {data &&
            data.length > 0 &&
            data.map((item: AffiliateLogoConfigType, i: number) => (
              <div className="Cards" key={i}>
                <ShopItem>
                  <ShopLogo onClick={() => _handleRedirectShop(item.url || '')}>
                    {item.image && item.image !== '' ? (
                      <ShopLogoImage src={item.image} alt="supplier-logo" style={{ width: '80%', height: '80%' }} />
                    ) : (
                      <NoImageFound>
                        <p>Image not found.</p>
                      </NoImageFound>
                    )}
                  </ShopLogo>
                  <ShopTitle onClick={() => _handleRedirectShop(item.url || '')} title={item.name || ''}>
                    {item.name || ''}
                  </ShopTitle>
                  {item.commission ? <SaleLabel>Gives up to {item.commission}%</SaleLabel> : null}
                </ShopItem>
              </div>
            ))}
        </div>
      </ShopItems>
    </AffiliateMerchantStyle>
  )
}

export default AffiliateMerchants
