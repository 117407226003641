import styled from 'styled-components'

export const ShopStyle = styled.div`
  width: 100%;
  .mb-20 {
    margin-bottom: 20px;
  }

  /* .pagination {
    width: 96%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
    list-style: none;
    margin-right: 0 !important;;
    margin-left: 0;
    outline: none;
    @media only screen and (max-width: 767px) {
      justify-content: center;
      
    }
  } */

  .pagination {
    display: flex !important;
    justify-content: flex-end;
    flex-direction: row;
    /* padding-left:0 !important; */
    margin-right: 0 !important;
    li {
      /* display: flex; */
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      /* &:not(:last-child) {
      border-right: 1px solid #ddd;
    } */
      a {
        text-decoration: none;
        display: block;
        font-size: 14px;

        cursor: pointer;
        color: #337ab7;
        background-color: #fff;
        border-color: #ddd;

        border: 1px solid #bebbbb;
      }
      .customIcon {
        font-size: 10px;
      }
    }
    /* border: 1px solid #ddd; */

    li.active {
      a {
        color: #fff;
        background-color: #337ab7;
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
      }
    }
    .disabled a {
      color: #777;
      cursor: not-allowed;
    }
  }
`

export const TextStyle = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #797979;
  line-height: 24px;
  margin: 0;
`

export const TitleComponent = styled.h3`
  padding: 15px;
  padding-left: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #66ccff;
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 15px;
  border-bottom: 1px solid #eff2f7;
`

export const ShopContentStyle = styled.div`
  padding: 30px 90px 45px;
  background: #ffffff;

  @media only screen and (max-width: 768px) {
    padding: 20px 40px;
  }
  @media only screen and (max-width: 500px) {
    padding: 20px 20px;
  }
  .pagination {
    @media only screen and (max-width: 767px) {
      justify-content: center !important;
      width: 100% !important;
      padding-left: 0 !important;
    }
  }
`

export const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 300px;
`
export const SearchWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  input {
    height: 42px !important;
    width: 100%;
  }
  .search-icon {
    height: 44px;
    padding: 5px 15px;
    transition: all 0.2s;
    /* &:hover {
      background-color: #f48221;
      color: #fff;
    } */
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: block !important;
    text-align: center;
    .custiomPagination {
      padding: unset;
    }
    .pagination {
      padding: unset;
    }
  }

  .custiomPagination {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    flex-direction: row;
    /* @media (max-width:600px){
      padding-left:20px;
    } */
    @media (max-width: 500px) {
      padding-left: 0px;
    }
    li {
      /* display: flex; */
      list-style: none;
      width: 32px;
      display: block;
      height: 32px;
      text-align: center;
      line-height: 32px;
      &:not(:last-child) {
        /* border-right: 1px solid #bebbbb; */
        @media (max-width: 340px) {
          width: 29px;
        }
      }

      a {
        text-decoration: none;
        font-size: 14px;
        display: block;
        cursor: pointer;
        color: #337ab7;
        background-color: #fff;
        border-color: #ddd;

        border: 1px solid #bebbbb;
      }
      .customIcon {
        font-size: 10px;
      }
    }

    li.active {
      a {
        color: #fff;
        background-color: #337ab7;
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
        color: #fff;
        background-color: #337ab7;
      }
    }
    .disabled a {
      color: #777;
      cursor: not-allowed;
    }
    a svg {
      margin: auto;
      display: flex;
      align-items: center;
      font-size: 8px;
      height: 32px;
    }
  }
`
