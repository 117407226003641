import styled from 'styled-components'

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 14000;
  align-items: center;
  display: flex;
  justify-content: center;
  /* position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.65);
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1000; */
`

export const ModalContent = styled.div`
  max-width: 350px;
`

export const ViewSlider = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
`

export const PreviousButton = styled.a`
  cursor: pointer;
  width: 30%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`

export const NextButton = styled.a`
  cursor: pointer;
  width: 30%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`

export const ViewImage = styled.div`
  display: inline-flex;
  width: 100%;

  img {
    width: 100%;
    max-height: 337px;
    max-width: 337px;
    background: #fff;
    padding: 10px;
  }
`

export const ViewTitle = styled.div`
  width: 100%;
  text-align: center;
  display: inline-block;
`

export const Title = styled.span`
  color: #fff;
  padding: 4px 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
  font-size: 13px;
  display: inline-block;
`

export const ModalBody = styled.div`
  background-color: #fff;
  padding: 15px 15px;
  button {
    background: none;
    outline: none;
    border: none;
    margin-left: auto;
    display: block;
    padding: 0px 10px 15px 0px;
  }
  .borderLine {
    border-bottom: 1px solid #dee2e6;
    margin: 0px -15px;
  }
`
