import { combineReducers } from '@reduxjs/toolkit'

import affiliateLogoSlice from './slices/affiliate-logo-slice'
import userSlice from './slices/user-slice'

const rootReducer = combineReducers({
  affiliateLogoSlice,
  userSlice,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
