import React from 'react'
import ShopContent from '../../components/shop'

import 'normalize.css'
import '@procurenetworks/components-unified-header/build/pn-unified-header.css'

const IndexPage: React.FC = () => {
  return (
    <div className="bg-wrap">
      <ShopContent />
    </div>
  )
}

export default IndexPage
