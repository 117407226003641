/* stylelint-disable font-family-no-missing-generic-family-keyword */
import styled from 'styled-components'

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CheckBoxLabel = styled.div<{ disabled: boolean }>`
  font-family: Helvetica;
  font-weight: normal;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props => props.disabled && 'color: #999;'}
`

export const StyledCheckbox = styled.input`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-right: 10px;
`
