import styled from 'styled-components'

export const AffiliatesListStyle = styled.div`
  width: 100%;
  padding: 20px 20px;
  background: #fff;
  box-sizing: border-box;

  .newButton {
    margin-left: 0 !important;
    margin-bottom: 20px;
  }
`

export const TitlePage = styled.h3`
  color: #66ccff;
  padding: 10px 15px 10px 0px;
  padding-left: 0;
  margin: 0 0 20px;
  border-bottom: 1px solid rgb(239, 242, 247);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
`
