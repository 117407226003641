import React, { FC, useCallback, useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
  DataGrid,
  DataGridCellSortState,
  GridColDef,
  GridSortModel,
  useLocalStorage,
} from '@procurenetworks/procure-component-library'
import { formatCurrencyAmount, _filterRows } from '../../utils'
import { AffiliateLogoConfigType } from '../../common/types/affiliate-logo-config'
import SearchBox from '../common/search-input'
import Modal from '../common/modal'
import ModalSlider from '../common/modal-slider'
import { CustomLoader } from '../common/custom-loader'
import { AffiliateStyle, LogoStyle, CheckedStyle, UnCheckedStyle, Container } from './affiliate-table-styles'
import { debounce } from 'lodash'
import { Box } from '@mui/material'

interface Props {
  loading: boolean
  affiliates: AffiliateLogoConfigType[]
  onChangeSearch: (parameters: string) => void
  totalLength?: number
  persistKey?: string
}

type ImageView = {
  index: number
  image: any
  name: string | undefined
}
const AffiliateTableComponent: FC<Props> = ({ affiliates, onChangeSearch, loading, totalLength, persistKey }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filteredContacts, updateFilteredContacts] = useState(affiliates)
  const [sourceImage, setSourceImage] = useState<string | null | undefined>()
  const [nameLogo, setNameLogo] = useState<string | null | undefined>()
  const [isViewImage, setIsViewImage] = useState(false)
  const [imageView, setImageView] = useState<ImageView | null | undefined>()
  const [columnOrderState, setColumnOrderState] = useState<DataGridCellSortState>({ id: 'name', orderBy: 'desc' })
  const [datagridState] = useLocalStorage(persistKey)

  const tabelColumns = [
    {
      id: 'image',
      label: 'Logo',
      sortable: false,
      value: 'image',
      valueNode: ({ row }: any, i: number) => {
        return (
          <LogoStyle onClick={() => handleViewLogo(i, row)}>
            <img src={row.image} alt="" />
          </LogoStyle>
        )
      },
      width: 125,
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      value: 'name',
      // eslint-disable-next-line react/display-name
      valueNode: ({ row }: any) => (
        <Link
          to={{
            pathname: `/admin/affiliate-configuration/edit/${row._id}`,
            state: { row_id: row._id },
          }}
        >
          {row.name}
        </Link>
      ),
      width: 150,
    },

    {
      id: 'commission',
      label: 'Commission',
      sortable: true,
      value: 'commission',
      valueNode: ({ row }: any) => (
        <div style={{ marginLeft: '10px' }}>
          {row.commission === '' || !row.commission ? '' : `$${formatCurrencyAmount(row.commission)}`}
        </div>
      ),
    },

    {
      id: 'is_enabled',
      label: 'Enabled',
      sortable: true,
      value: 'is_enabled',
      valueNode: ({ row }: any) =>
        row.is_enabled ? (
          <CheckedStyle>
            <FaCheck />
          </CheckedStyle>
        ) : (
          <UnCheckedStyle>
            <FaTimes />
          </UnCheckedStyle>
        ),
    },

    {
      id: 'created_by',
      label: 'Created By',
      sortable: true,
      value: 'created_by',
      valueNode: ({ row }: any) => (
        <div>
          {(row.created_by && row.created_by.first_name) || ''} {(row.created_by && row.created_by.last_name) || ''}
        </div>
      ),
    },
    {
      id: 'updated_by',
      label: 'Updated By',
      sortable: true,
      value: 'updated_by',
      valueNode: ({ row }: any) => (
        <div>
          {(row.updated_by && row.updated_by.first_name) || ''} {(row.updated_by && row.updated_by.last_name) || ''}
        </div>
      ),
    },
    {
      id: 'updatedAt',
      label: 'Last Updated',
      sortable: false,
      value: 'updatedAt',
      valueNode: ({ row }: any) => <div>{moment(row.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
  ]

  const sortData = (data: any) => {
    const { id, orderBy } = columnOrderState
    let sortedData = data.map((item: any, i: number) => {
      return { ...item, id: i }
    })

    if (id && (id === 'created_by' || id === 'updated_by') && (orderBy === 'asc' || orderBy === 'desc')) {
      sortedData = data?.sort((a: any, b: any) => {
        return orderBy === 'asc'
          ? a[id]?.first_name.toLowerCase() < b[id]?.first_name.toLowerCase()
            ? 1
            : b[id]?.first_name.toLowerCase() < a[id]?.first_name.toLowerCase()
            ? -1
            : 0
          : a[id]?.first_name.toLowerCase() > b[id]?.first_name.toLowerCase()
          ? 1
          : b[id]?.first_name.toLowerCase() > a[id]?.first_name.toLowerCase()
          ? -1
          : 0
      })
    } else if (id && id === 'commission' && (orderBy === 'asc' || orderBy === 'desc')) {
      sortedData = sortedData?.sort((a: any, b: any) => {
        const commissionA = a[id] ? a[id] : 0
        const commissionB = b[id] ? b[id] : 0
        return orderBy === 'asc' ? commissionA - commissionB : commissionB - commissionA
      })
    } else if (id && id === 'name' && (orderBy === 'asc' || orderBy === 'desc')) {
      sortedData = sortedData?.sort((a: any, b: any) => {
        return orderBy === 'asc'
          ? a.name.toLowerCase() < b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() < a.name.toLowerCase()
            ? -1
            : 0
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : b.name.toLowerCase() > a.name.toLowerCase()
          ? -1
          : 0
      })
    } else if (id && id === 'is_enabled' && (orderBy === 'asc' || orderBy === 'desc')) {
      sortedData = sortedData?.sort((a: any, b: any) => {
        return orderBy === 'asc'
          ? a.is_enabled === b.is_enabled
            ? 0
            : a.is_enabled
            ? -1
            : 1
          : a.is_enabled === b.is_enabled
          ? 0
          : a.is_enabled
          ? 1
          : -1
      })
    } else {
      sortedData = data.map((item: any, i: number) => {
        return { ...item, id: i }
      })
    }
    return sortedData
  }
  const onSortModelChange = (model: GridSortModel) => {
    const gridSortModel = model.find((item: any) => item)
    setColumnOrderState({
      id: gridSortModel?.field || undefined,
      orderBy: gridSortModel ? (gridSortModel?.sort === 'asc' ? 'desc' : 'asc') : undefined,
    })
  }

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPage(page)
    },
    [setPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
    },
    [setRowsPerPage, setPage],
  )

  const _handleSearchEvent = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent) => {
      const inputValue = (event as any).target.value
      const filteredRows = inputValue ? _filterRows<any>(affiliates, inputValue) : affiliates
      updateFilteredContacts(filteredRows)
      onChangeSearch(inputValue ?? '')
    }, 1000),
    [affiliates, onChangeSearch],
  )

  useEffect(() => {
    const gridSortModel = datagridState?.sorting?.sortModel?.find((item: any) => item)
    setColumnOrderState({
      id: gridSortModel?.field || undefined,
      orderBy: gridSortModel ? (gridSortModel?.sort === 'asc' ? 'desc' : 'asc') : undefined,
    })
    updateFilteredContacts(affiliates)
  }, [affiliates])

  const handleViewLogo = useCallback(
    (index: number, item: AffiliateLogoConfigType) => {
      setIsViewImage(true)
      setImageView({
        image: item.image,
        index,
        name: item.name,
      })
    },
    [setIsViewImage, setImageView],
  )
  const _handleCloseViewImage = useCallback(() => {
    setIsViewImage(false)
    setNameLogo('')
    setSourceImage('')
  }, [setIsViewImage, setSourceImage, setNameLogo])

  const data = useMemo(() => {
    const affiliateData =
      rowsPerPage > 0 ? affiliates.slice((page + 1) * rowsPerPage, (page + 1) * rowsPerPage + rowsPerPage) : affiliates
    return affiliateData
  }, [affiliates])

  const _handlePreviousLogo = useCallback(() => {
    const total = data.length
    if (imageView?.index === 0) {
      setImageView({
        image: data[total - 1].image,
        index: total - 1,
        name: data[total - 1].name,
      })
    } else {
      const index = (imageView && imageView.index) || 1
      setImageView({
        image: data[index - 1].image,
        index: index - 1,
        name: data[index - 1].name,
      })
    }
  }, [setImageView, imageView, affiliates, rowsPerPage, page])

  const _handleNextLogo = useCallback(() => {
    const total = data.length
    if (imageView?.index === total - 1) {
      setImageView({
        image: data[0].image,
        index: 0,
        name: data[0].name,
      })
    } else {
      const index = (imageView && imageView.index) || 0
      setImageView({
        image: data[index + 1].image,
        index: index + 1,
        name: data[index + 1].name,
      })
    }
  }, [setImageView, imageView, affiliates, rowsPerPage, page])

  const columns: GridColDef[] = tabelColumns.map((headCell: any) => {
    const column: GridColDef = {
      align: headCell.rowAlign,
      field: headCell.value,
      headerAlign: headCell.labelAlign,
      headerName: headCell.label,
      headerClassName: headCell.classes,
      renderCell: headCell.valueNode
        ? (parameters: any) => {
            return <headCell.valueNode headCell={headCell} row={parameters.row} />
          }
        : undefined,
      renderHeader: headCell.labelNode
        ? () => {
            const LabelComponent = headCell.labelNode
            return <LabelComponent headCell={headCell} />
          }
        : undefined,
      sortable: headCell.sortable,
    }

    if (headCell.width) {
      column.width = headCell.width
    } else {
      column.flex = headCell.flex ? headCell.flex : 1
    }

    return column
  })
  return (
    <AffiliateStyle>
      <SearchBox
        onChange={_handleSearchEvent}
        total={affiliates.length}
        currentTotal={filteredContacts.length}
        rowsPerPage={rowsPerPage}
        page={page + 2}
        autoFocus
        totalLength={totalLength}
      />
      {loading ? (
        <Container>
          <CustomLoader active />
        </Container>
      ) : (
        <Box
          sx={{
            ' .MuiInputBase-root ': {
              marginBottom: '1rem',
            },
            ' .MuiTablePagination-actions': {
              marginBottom: '1rem',
            },
          }}
        >
          <DataGrid
            columns={columns}
            rows={(rowsPerPage > 0
              ? sortData(affiliates).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : sortData(affiliates)
            ).map((item: any, i: any) => {
              return { ...item, id: i }
            })}
            pagination
            rowsPerPage={rowsPerPage}
            count={affiliates.length}
            rowHeight={affiliates?.length ? 80 : 40}
            currentPage={affiliates?.length ? page : 0}
            multipleSelection={false}
            loading={loading}
            minWidth={1024}
            persistKey={persistKey}
            onTablePageChange={handleChangePage}
            onTableRowsPerPageChange={handleChangeRowsPerPage}
            onSortModelChange={onSortModelChange}
          />
        </Box>
      )}
      <Modal isOpen={isViewImage} onRequestClose={_handleCloseViewImage}>
        <div>{sourceImage && <img src={sourceImage} alt="" />}</div>
        <div>{nameLogo}</div>
      </Modal>
      <ModalSlider
        open={isViewImage}
        image={(imageView && imageView.image) || ''}
        title={(imageView && imageView.name) || ''}
        onPrev={_handlePreviousLogo}
        onNext={_handleNextLogo}
        onClose={_handleCloseViewImage}
      />
    </AffiliateStyle>
  )
}
export default AffiliateTableComponent
