import React from 'react'
import AffiliatesList from '../../../components/affiliate-configuration/affiliates-list'
import 'normalize.css'
import '@procurenetworks/components-unified-header/build/pn-unified-header.css'

const Affiliates: React.FC<any> = () => {
  return <AffiliatesList />
}

export default Affiliates
