import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { UnifiedProvider, UnifiedHeader } from '@procurenetworks/components-unified-header'
import { History } from 'history'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import config from '../../../common/config'
import { handleResponseCodeRedirection } from '../../../utils/redirect-helper'
import {getProfile, userSelector} from '../../../store/slices/user-slice'
import { PageBackground } from 'src/common/theme'

interface UnifiedHeaderWrapperProps {
  history: History
}

const UnifiedHeaderWrapper: React.FC<RouteComponentProps<undefined, {}, UnifiedHeaderWrapperProps>> = ({
  children,
  history,
}) => {
  const [headerConfig, setHeaderConfig] = useState({ ...config.unifiedHeaderConfig, isLoggedIn: false })
  const selector = useSelector(userSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    if (selector.user) {
      setHeaderConfig({
        ...config.unifiedHeaderConfig,
        isLoggedIn: true,
      })
    } else if (selector.error && selector.error.name) {
      const errorCode = Number.parseInt(selector.error.name)
      handleResponseCodeRedirection(history as unknown as History, errorCode)
    }
  }, [selector.user, selector.error])

  return (
    <UnifiedProvider {...headerConfig}>
      <UnifiedHeader/>
      <PageBackground>
        {children}
      </PageBackground>
    </UnifiedProvider>
  )
}

export default withRouter(UnifiedHeaderWrapper)
