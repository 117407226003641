import qS from 'query-string'
import config from '../config'
import { NetworkService } from '../services'
import { isServer } from '../utils/platform-utils'
import { AffiliateLogoConfigType } from '../types/affiliate-logo-config'
import { filterFalsyKeys, toFormData, filterNilKeys } from '../../utils'

interface AffiliateController {
  getAffiliatesConfiguration: (name: string, admin: boolean) => Promise<AffiliateLogoConfigType>
}

class AffiliateServiceClient implements AffiliateController {
  private readonly _networkService: NetworkService

  private readonly _basePath: string

  constructor(baseUri: string | undefined, basePath: string) {
    this._networkService = new NetworkService(baseUri)
    this._basePath = basePath
  }

  private defaultErrorHandler = (error: any) => {
    if (error.redirect) {
      if (!isServer) {
        // window.location.assign(error.redirectionUrl)
      }
    }
    throw error
  }

  // supplier controllers
  public getAffiliatesConfiguration(name?: string, admin?: boolean) {
    const query = qS.stringify({ admin, name })
    return this._networkService.get({
      errorCallback: this.defaultErrorHandler,
      restConfig: {
        withCredentials: true,
      },
      url: `${this._basePath}/affiliate-logo-config?${query}`,
    })
  }

  // get detail affiliate logo config
  public getDetailAffiliateLogoConfig(id: string) {
    return this._networkService.get({
      errorCallback: this.defaultErrorHandler,
      restConfig: {
        withCredentials: true,
      },
      url: `${this._basePath}/affiliate-logo-config/${id}`,
    })
  }

  // create affiliate logo config
  public createAffiliateLogoConfig(payload: AffiliateLogoConfigType) {
    const formData = toFormData(filterFalsyKeys(payload))
    return this._networkService.post({
      body: formData,
      errorCallback: this.defaultErrorHandler,
      restConfig: {
        withCredentials: true,
      },
      url: `${this._basePath}/affiliate-logo-config/add`,
    })
  }

  // update affiliate logo config
  public updateAffiliateLogoConfig(id: string, payload: AffiliateLogoConfigType) {
    let data = filterNilKeys(payload)
    if(!data?.is_enabled){
      data['is_enabled'] = false
    }
    const formData = toFormData(data)
    return this._networkService.put({
      body: formData,
      errorCallback: this.defaultErrorHandler,
      restConfig: {
        withCredentials: true,
      },
      url: `${this._basePath}/affiliate-logo-config/${id}`,
    })
  }

  // delete affiliate logo config
  public deleteAffiliateLogoConfig(id: string) {
    return this._networkService.delete({
      errorCallback: this.defaultErrorHandler,
      restConfig: {
        withCredentials: true,
      },
      url: `${this._basePath}/affiliate-logo-config/${id}`,
    })
  }
}

const baseUri = config.backendUrl

export default new AffiliateServiceClient(baseUri, '/api')
