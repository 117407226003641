import React, { useCallback } from 'react'
import { Modal } from 'reactstrap'
import { AiOutlineClose } from 'react-icons/ai'
import {
  AffiliateMerchantStyle,
  ShopItems,
  ShopItem,
  ShopTitle,
  ShopLogo,
  AmountLabel,
  MerchantLabel,
  ShopLogoImage,
  NoImageFound,
  NoItemFound,
} from './item-modal-style'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function ItemModal({ open, setOpen, data }) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const _handleRedirectShop = useCallback(url => {
    const win = window?.open(url, '_blank')
    win.focus()
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <div>
      <AffiliateMerchantStyle>
        <ShopItems>
          <div className="shopCard">
            {data &&
              data.length > 0 &&
              data.map((item, i) => (
                <div className="Cards" key={i}>
                  <AiOutlineClose className="close_icon" onClick={handleClose} />

                  <ShopItem>
                    <ShopLogo onClick={() => _handleRedirectShop(item.clickURL || '')}>
                      {item?.images?.nodes?.length > 0 ? (
                        <ShopLogoImage src={item.images.nodes[0].original} alt="" />
                      ) : (
                        <NoImageFound>
                          <p>Image not found.</p>
                        </NoImageFound>
                      )}
                    </ShopLogo>

                    <ShopTitle onClick={() => _handleRedirectShop(item.clickURL || '')} title={item.name || ''}>
                      {item.name || ''}
                    </ShopTitle>
                    <AmountLabel>
                      ${(item.currentPrice && Number(item.currentPrice.amount).toFixed(2)) || ''}
                    </AmountLabel>
                    <MerchantLabel>{(item.merchant && item.merchant.name) || ''}</MerchantLabel>
                    {/* <SaleLabel>Gives up to {item?.merchant?.maximumCommissionRate * 100}%</SaleLabel> */}
                  </ShopItem>
                </div>
              ))}
            {data && data?.length === 0 && (
              <NoItemFound>
                <AiOutlineClose className="close_icon" onClick={handleClose} />
                <h2>No Offers Found</h2>
              </NoItemFound>
            )}
          </div>
        </ShopItems>
      </AffiliateMerchantStyle>
    </div>
  )

  return (
    <Modal
      isOpen={open}
      className={data?.length ? 'ModalBody' : 'ModalBodyEmpty'}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  )
}
