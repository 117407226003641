import React from 'react'
import { Dialog } from '@procurenetworks/procure-component-library'
import { IoMdClose } from 'react-icons/io'
import { PreviousButton, ViewImage, ViewTitle, Title, ViewSlider, ModalBody, NextButton } from './index-styles'

interface Props {
  open: boolean
  image?: string | undefined
  title?: string | object | undefined
  onPrev?: () => void
  onNext?: () => void
  onClose: () => void
}

const ModalSlider: React.FC<Props> = ({ open, image, title, onPrev, onNext, onClose }) => {
  return (
    <Dialog
      id="modal"
      open={open}
      fullWidth={false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ModalBody>
        <div className="borderLine">
          <button type="button" onClick={onClose}>
            <IoMdClose size={24} />
          </button>
        </div>
        <div>
          <ViewSlider>
            <PreviousButton onClick={onPrev} />
            <ViewImage>{image && <img src={image} alt="" />}</ViewImage>
            <NextButton onClick={onNext} />
          </ViewSlider>
        </div>
        <div>
          <ViewTitle>
            <Title>{title}</Title>
          </ViewTitle>
        </div>
      </ModalBody>
    </Dialog>
  )
}

export default ModalSlider
