/* stylelint-disable font-family-no-missing-generic-family-keyword */
import styled from 'styled-components'

export const SearchStyle = styled.div`
  width: 100%;
  .flex-container {
    display: flex;
  }
  .searchField {
    border: none;
    padding-right: 7px;
    width: 90%;
    padding: 8px 0 8px 12px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    color: #777;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    &::placeholder {
      font-weight: normal;
    }
  }

  .viewSearchIcon {
    border-radius: 4px 0 0 4px !important;
  }
`
export const SearchLabel = styled.div`
  font-family: Helvetica;
  font-weight: 600;
  color: #797979;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 10px;
`

export const DisplayResults = styled.p`
  width: 100%;
  margin: 10px 0 15px;
  font-size: 13px;
  color: #797979;
`

export const SearchBoxInline = styled.div`
  justify-content: space-between;
  align-items: center;
  outline: none;
  min-width: 223px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
  display: flex;
  .clearButton {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: rgb(119, 119, 119);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const SearchIconStyle = styled.div`
  border: 1px solid #ccc;
  border-left: none;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  color: #666;
  cursor: pointer;

  .viewSearchIcon {
    border-radius: 4px 0 0 4px;
  }
`
