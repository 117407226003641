import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { isFunction } from '../../utils/type-utils'
import {logout, SESSION_KEY} from '../../../const/auth'
import { redirectToLoginWithContinueUrl } from '../../../utils/redirect-helper'
import {
  BodilessRequestConfig,
  DefaultRequestConfig,
  GetRequestConfig,
  Headers,
  Parameters,
  PostRequestConfig,
  PutRequestConfig,
} from './types'
import cookie from "../../../utils/cookie";

class NetworkService {
  private readonly _axios: AxiosInstance

  private readonly _baseUri: string

  private _defaults: DefaultRequestConfig = { headers: {}, params: {} }

  constructor(baseUri: string | undefined) {
    if (!baseUri) {
      throw new Error('Base uri is required')
    }
    this._baseUri = baseUri
    this._axios = axios.create({
      baseURL: baseUri,
    })
    this._setupRequestInterceptor()
    this._setupResponseInterceptor()
  }

  private _setupRequestInterceptor() {
    this._axios.interceptors.request.use(
      config => {
        const accessToken = cookie.get(SESSION_KEY)
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
      },
      error => {
        return Promise.reject(error)
      },
    )
  }

  // Note: Added Response Interceptor to Axios so If we get 401 at any point, We will redirect to Login Page
  private _setupResponseInterceptor() {
    this._axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response
      },
      (error: AxiosError) => {
        const { response } = error
        const { status } = response || {}
        const responseCode = status ? Number(status) : 503
        if (responseCode === 401) {
          logout()
          redirectToLoginWithContinueUrl()
        }
        return Promise.reject(error)
      },
    )
  }

  public setDefaultHeaders(headers: Headers = {}) {
    this._defaults.headers = headers
  }

  public setDefaultParams(parameters: Parameters = {}) {
    this._defaults.params = parameters
  }

  public async get({
    headers,
    url,
    params,
    restConfig = {},
    successCallback,
    errorCallback,
  }: GetRequestConfig): Promise<any> {
    const config = this._getRequestConfig({ headers, params, restConfig })
    const computedUrl = this._baseUri + url
    try {
      const response = await this._axios.get(computedUrl, config)
      if (isFunction(successCallback)) {
        return successCallback(response.data)
      }
      return response.data
    } catch (error: any) {
      const error_ = (error.response && error.response.data) || error
      if (isFunction(errorCallback)) {
        return errorCallback(error_)
      }
      throw error_
    }
  }

  public async delete({
    headers,
    url,
    params,
    restConfig = {},
    successCallback,
    errorCallback,
  }: GetRequestConfig): Promise<any> {
    const config = this._getRequestConfig({ headers, params, restConfig })
    const computedUrl = this._baseUri + url
    try {
      const response = await this._axios.delete(computedUrl, config)
      if (isFunction(successCallback)) {
        return successCallback(response.data)
      }
      return response.data
    } catch (error: any) {
      const error_ = (error.response && error.response.data) || error
      if (isFunction(errorCallback)) {
        return errorCallback(error_)
      }
      throw error_
    }
  }

  public async post({
    headers,
    url,
    params,
    body,
    restConfig = {},
    successCallback,
    errorCallback,
  }: PostRequestConfig): Promise<any> {
    const config = this._getRequestConfig({ headers, params, restConfig })
    const computedUrl = this._baseUri + url
    try {
      const response = await this._axios.post(computedUrl, body, config)
      if (isFunction(successCallback)) {
        return successCallback(response.data)
      }
      return response.data
    } catch (error: any) {
      const error_ = (error.response && error.response.data) || error
      if (isFunction(errorCallback)) {
        return errorCallback(error_)
      }
      throw error_
    }
  }

  public async put({
    headers,
    url,
    params,
    body,
    restConfig = {},
    successCallback,
    errorCallback,
  }: PutRequestConfig): Promise<any> {
    const config = this._getRequestConfig({ headers, params, restConfig })
    const computedUrl = this._baseUri + url
    try {
      const response = await this._axios.put(computedUrl, body, config)
      if (isFunction(successCallback)) {
        return successCallback(response.data)
      }
      return response.data
    } catch (error: any) {
      const error_ = (error.response && error.response.data) || error
      if (isFunction(errorCallback)) {
        return errorCallback(error_)
      }
      throw error_
    }
  }

  private _getRequestConfig({
    headers = {},
    params: parameters = {},
    restConfig = {},
  }: Pick<BodilessRequestConfig, 'headers' | 'params' | 'restConfig'>) {
    return {
      ...restConfig,
      headers: { ...this._defaults.headers, ...headers },
      params: { ...this._defaults.params, ...parameters },
    }
  }
}

export default NetworkService
