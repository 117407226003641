import React from 'react'
import { withStyles, Theme } from '@material-ui/core/styles'
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch'
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
}

const IOSSwitch = withStyles((theme: Theme) => ({
  checked: {},
  focusVisible: {},
  root: {
    height: 27,
    margin: theme.spacing(1),
    marginLeft: 0,
    padding: 2,
    width: 43,
  },
  switchBase: {
    '&$checked': {
      '& + $track': {
        backgroundColor: '#52d869',
        border: 'none',
        opacity: 1,
      },
      color: theme.palette.common.white,
      transform: 'translateX(16px)',
    },
    '&$focusVisible $thumb': {
      border: '6px solid #fff',
      color: '#52d869',
    },
    padding: 3,
  },
  thumb: {
    height: 21,
    width: 21,
  },
  track: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}))(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        checked: classes.checked,
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }}
      {...props}
    />
  )
})

export default IOSSwitch
