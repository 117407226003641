import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { polyfill } from 'smoothscroll-polyfill'
import config from './common/config'
import AppRoutes from './router/AppRoutes'
import 'smoothscroll-anchor-polyfill'
import Error5xx from './pages/error-5xx'
import { development } from './utils/environment-utils'
import theme from './common/theme'
import 'normalize.css'
import './assets/styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  useEffect(() => {
    polyfill()
  }, [])

  const coreToken: any = config.pnCoreSession
  const userID: any = '621'
  const headers = new Headers()
  headers.append('token', coreToken)
  headers.append('userId', userID)

  const httpLink = createHttpLink({
    uri: config.unifiedHeaderConfig.env.apiUrls.hoover,
  })

  const authLink = setContext(context => {
    return {
      ...context,
      headers: {
        ...headers,
        // token: coreToken,
        // userId: userID,
      },
    }
  })
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  })

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Sentry.ErrorBoundary fallback={<Error5xx />} showDialog={!development}>
                <AppRoutes />
            </Sentry.ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
