import React, { useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Pagination } from '@procurenetworks/procure-component-library'
import { getAllAffiliateLogoConfig, affiliateLogoSelector } from '../../store/slices/affiliate-logo-slice'
import { convertToISBN13 } from '../../utils'
import { GET_SHOPS } from '../../common/queries'
import ProductsMerchants from './products-merchants'
import AffiliateMerchants from './affiliate-merchants'
import SearchBox from '../common/advance-search-input'
import { CustomLoader } from '../common/custom-loader'
import { LoaderContainer, SearchWrap, ShopContentStyle, ShopStyle, TextStyle, TitleComponent } from './index-styles'
import PageTitle from '../PageTitle'
import { PAGE_TITLE } from 'src/common/constants'
import { Box } from '@mui/material'
interface Props {
  data?: any
}

interface SearchQuery {
  name?: string
  code?: string
  brand?: string
  model?: string
  isbn?: string
}

const ShopContent: React.FC<Props> = () => {
  const [search, setSearch] = useState('')
  const [checked, setChecked] = useState('code')
  const [isShowProducts, setIsShowProducts] = useState(false)
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [, setCurrentPage] = useState(0)
  const [postsPerPage, setPostPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [searchQuery, setSearchQuery] = useState<{ [key: string]: any }>({
    brand: '',
    code: '',
    isbn: '',
    model: '',
    name: '',
    simple: {},
  })
  const dispatch = useDispatch()
  const selector = useSelector(affiliateLogoSelector)
  const [getProducts, { loading, data }] = useLazyQuery(GET_SHOPS, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    dispatch(getAllAffiliateLogoConfig({ name: '' }))
  }, [])

  useEffect(() => {
    if (search.length === 0 || !search) {
      setIsShowProducts(false)
    }
  }, [search, setIsShowProducts])

  const handleChangeSearchEvent = useCallback(
    (
      event:
        | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      const keySearch = (event as any) || ''
      console.log(keySearch)
      setSearch(keySearch)
      setPostPerPage(10)
      // setPosts('')
    },
    [setSearch],
  )

  const getQuery = (names: string, pages: number, totalPages: number) => {
    const queryParameters = {
      ...searchQuery,
      page: pages,
      totalPage: totalPages,
    }

    // return queryParameters
    if (!checked || checked === 'name') {
      return { ...queryParameters, name: names }
    }
    if (checked === 'code') {
      return { ...queryParameters, code: names }
    }
    if (checked === 'model') {
      return { ...queryParameters, model: names }
    }
    if (checked === 'brand') {
      return { ...queryParameters, brand: names }
    }
    if (checked === 'isbn') {
      return { ...queryParameters, code: convertToISBN13(names) }
    }
    return {}
  }
  const getQueryForModal = (searchQueryData: SearchQuery, pages: number, totalPages: number) => {
    return {
      brand: searchQueryData.brand,
      code: searchQueryData.code || convertToISBN13(searchQueryData?.isbn),
      model: searchQueryData.model,
      name: searchQueryData?.name,
      page: pages,
      totalPage: totalPages,
    }
  }

  const [nameSearch, setName] = useState('')
  const handleSearch = useCallback(async () => {
    console.log('pre')
    setIsShowProducts(true)
    searchQuery[checked] = ''
    setName(search)

    const variables = getQuery(search || nameSearch, 0, postsPerPage)
    if (isEmpty(variables)) {
      setSearchQuery({
        brand: '',
        code: '',
        isbn: '',
        model: '',
        name: '',
        simple: {},
      })
    }
    console.log(variables)
    await getProducts({
      variables,
    })
    setIsShowProducts(true)
    setCurrentPage(0)
    setPage(0)
  }, [getProducts, search, setIsShowProducts, setIsButtonPressed, checked, searchQuery])

  const onSearch = useCallback(
    async _data => {
      const query = { ..._data }
      if (!isEmpty(query.simple)) {
        query[checked] = query.simple[checked]
      }
      delete query.simple

      setIsShowProducts(true)
      if (Object.values(query).filter(item => item).length > 0) {
        const variables = getQueryForModal(query, 0, postsPerPage)

        await getProducts({
          variables,
        })

        await setIsShowProducts(true)
      } else {
        await setIsShowProducts(false)
      }
      setCurrentPage(0)
    },
    [searchQuery],
  )

  const paginateTo = (pageNumber: React.SetStateAction<number>) => setCurrentPage(+pageNumber - 1)


  const handlePageClick = async (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    if (!Number.isNaN(page)) {
      setPage(page)
      let variables = getQuery(nameSearch, page * postsPerPage, postsPerPage)
      const query: any = { ...searchQuery }
      if (!query[checked] && query.simple[checked]) {
        query[checked] = query.simple[checked]
      }
      const values = Object.values(query).filter(element => element)
      if (values.length > 0) {
        variables = getQueryForModal(query, page * postsPerPage, postsPerPage)
      }
      await getProducts({
        variables,
      })
      paginateTo(page)
    }
  }

  useEffect(() => {
    if (isButtonPressed) {
      setCurrentPage(0)
    }
  }, [search])
  const getTotalCount = (count: number) => {
    return count
  }
  const handleOnChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPostPerPage(Number(event.target.value))
    let variables = getQuery(nameSearch, 0, Number(event.target.value))
    const query: any = { ...searchQuery }
    if (!query[checked] && query.simple[checked]) {
      query[checked] = query.simple[checked]
    }
    const values = Object.values(query).filter(element => element)
    if (values.length > 0) {
      variables = getQueryForModal(query, 0, Number(event.target.value))
    }
    await getProducts({
      variables,
    })
    setCurrentPage(0)
    setPage(0)
  }

  const filteredData = data?.products.nodes.filter((item: any) => item.offers.nodes[0]?.merchant?.name)
  return (
    <ShopStyle>
      <PageTitle title={PAGE_TITLE.SHOP} />
      <ShopContentStyle>
        <div style={{ padding: 0 }}>
          <TitleComponent>Shops</TitleComponent>

          <TextStyle>Save More. Serve More.</TextStyle>
          <TextStyle className="mb-20">
            By using these vendors, you’re generating financial support for your organization.
          </TextStyle>
          <SearchWrap>
            <SearchBox
              label="Search Suppliers"
              placeholder="Search for brand or product using keywords or UPC code"
              hideResults
              autoFocus
              onChange={handleChangeSearchEvent}
              showIcon
              currentTotal={data && data.offers && data.offers.nodes.length}
              total={data && data.offers && data.offers.nodes.length}
              rowsPerPage={data && data.offers && data.offers.nodes.length}
              onSearch={handleSearch}
              checked={checked}
              setChecked={(event: string) => setChecked(event)}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onSearchModal={onSearch}
              loading={loading}
              setIsShowProducts={setIsShowProducts}
            />
          </SearchWrap>
          {selector?.loading || loading ? (
            <LoaderContainer>
              <CustomLoader active />
            </LoaderContainer>
          ) : (
            <>
              {isShowProducts ? (
                <>
                  {!loading && filteredData?.length > 0 && (
                    <Box
                      sx={{
                        ' .MuiInputBase-root ': {
                          marginBottom: '1rem',
                        },
                        ' .MuiTablePagination-actions': {
                          marginBottom: '1rem',
                        },
                      }}
                    >
                      <Pagination
                        page={page}
                        rowsPerPage={postsPerPage}
                        count={getTotalCount(data?.products.totalCount)}
                        onPageChange={handlePageClick}
                        onRowsPerPageChange={handleOnChange}
                      />
                    </Box>
                  )}
                  {loading ? (
                    <LoaderContainer className="pagination-loader" style={{ minHeight: 100 }}>
                      <CustomLoader active />
                    </LoaderContainer>
                  ) : filteredData?.length ? (
                    <ProductsMerchants data={data && data?.products.nodes && data?.products.nodes} />
                  ) : (
                    <p style={{ padding: '30px 0px', textAlign: 'center' }}>No matching records found.</p>
                  )}
                  {!loading && filteredData?.length > 0 && (
                    <Box
                      sx={{
                        ' .MuiInputBase-root ': {
                          marginBottom: '1rem',
                        },
                        ' .MuiTablePagination-actions': {
                          marginBottom: '1rem',
                        },
                      }}
                    >
                      <Pagination
                        page={page}
                        rowsPerPage={postsPerPage}
                        count={getTotalCount(data?.products.totalCount)}
                        onPageChange={handlePageClick}
                        onRowsPerPageChange={handleOnChange}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <AffiliateMerchants data={selector.affiliates} />
              )}
            </>
          )}
        </div>
      </ShopContentStyle>
    </ShopStyle>
  )
}

export default ShopContent
