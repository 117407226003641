import React from 'react'
import { Dialog, Card } from '@procurenetworks/procure-component-library'
import { IoMdClose } from 'react-icons/io'
import { ShopItem, NoImageFound } from './item-modal-style'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function ViewVendorModal({
  openSingleVendorModal,
  setSingleVendorModal,
  data,
  renderVendorImage,
  renderLogo,
  _handleRedirectShop,
  handleShowDialog,
}) {
  // getModalStyle is not a pure function, we roll the style only on the first render

  const handleClose = () => {
    setSingleVendorModal(false)
  }

  const filteredData = data?.filter(item => item?.merchant)
  const cardClasses = item => {
    switch (item?.length) {
      case 1:
        return 'singleCards'
      case 2:
        return 'twoVendor'
      default:
        return 'cards'
    }
  }

  const vendorCardClasses = item => {
    switch (item?.length) {
      case 1:
        return 'singleVendorCard'
      case 2:
        return 'twoCards'
      default:
        return 'vendorCard'
    }
  }

  const singleVendorCardClasses = item => {
    switch (item?.length) {
      case 1:
        return 'single'
      case 2:
        return 'twoCard'
      default:
        return 'Card'
    }
  }

  const body = (
    <div className={cardClasses(filteredData)}>
      <ShopItem>
        <button className="closeButton" type="button" onClick={handleClose}>
          <IoMdClose />
        </button>
        <div>
          {filteredData?.length > 0 ? (
            <div className={singleVendorCardClasses(filteredData)}>
              {filteredData &&
                filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <div className={vendorCardClasses(filteredData)} key={index}>
                    <Card
                      withImage
                      imagePath={item?.images?.nodes[0]?.original}
                      // title={item.offers.nodes[0].currentPrice.amount}
                      descriptionNode={
                        <div>
                          <p
                            className="vendorDescription"
                            onClick={event => _handleRedirectShop(item.clickURL || '', event)}
                          >
                            {item.name}
                          </p>
                          <p className="price">{`$${item?.currentPrice?.amount}`}</p>
                        </div>
                      }
                      target="_blank"
                      maxTitleLine={1}
                      data={item}
                      imageHeight={181}
                      onCardClick={() => handleShowDialog(renderVendorImage(item?.images?.nodes[0]?.original))}
                      cardActions={
                        <div
                          style={{ display: 'flex', height: '30px', margin: 'auto' }}
                          onClick={event => _handleRedirectShop(item.clickURL || '' || '', event)}
                        >
                          {renderLogo(item.merchant)}
                        </div>
                      }
                    />
                  </div>
                ))}
            </div>
          ) : (
            <NoImageFound>
              <div className="noImage">
                <p className="notFound">Image not found.</p>
              </div>
            </NoImageFound>
          )}
        </div>
      </ShopItem>
    </div>
  )

  return (
    <Dialog
      open={openSingleVendorModal}
      // className={item?.length ? "ModalBody" : 'ModalBodyEmpty'}
      // onClose={handleClose}
      // className="vendorModal"
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
      id="dialogBox"
    >
      {body}
    </Dialog>
  )
}
