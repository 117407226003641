import React, { useState, useCallback, useEffect } from 'react'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'
import { SearchBar } from '@procurenetworks/procure-component-library'
import { IoMdCloseCircle } from 'react-icons/io'
import { isEmpty, upperCase } from 'lodash'
import AdvanceSearchModal from './modal'
import { DisplayResults, SearchStyle, AlertError } from './index-styles'
const SearchOptions = [
  {
    label: 'Product Name',
    value: 'name',
  },
  {
    label: 'ISBN',
    value: 'isbn',
  },
  {
    label: 'UPC/ Product Code',
    value: 'code',
  },
  {
    label: 'Model / Model Number',
    validationRegex: /^\d*$/,
    value: 'model',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
]
interface Props {
  rowsPerPage: number
  total: number
  label?: string
  currentTotal: number
  hideResults?: boolean
  showIcon?: boolean
  placeholder?: string
  onChange: (
    event:
      | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  onSearch?: (data?: any) => void

  checked?: string
  setChecked?: any
  searchQuery?: { [key: string]: any }
  setSearchQuery?: (data?: any) => void
  onSearchModal?: (data: any) => void
  loading?: boolean
  autoFocus?: boolean
  setIsShowProducts?: (value: boolean) => void
}

const SearchBox: React.FC<Props> = ({
  rowsPerPage,
  total,
  currentTotal,
  onChange,
  hideResults,
  onSearch,
  checked,
  setChecked,
  searchQuery,
  setSearchQuery,
  onSearchModal,
  autoFocus,
  loading,
}) => {
  const [value, setValue] = useState('')
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [searchValue, setSearchValue] = useState<{
    name?: string
    code?: string
    isbn?: string
    model?: string
    brand?: string
    [key: string]: any
  }>({})

  const openModal = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (isEmpty(searchQuery)) {
      setChipData([
        { key: 0, label: 'Code', val: '' },
        { key: 1, label: 'Name', val: '' },
        { key: 2, label: 'Model', val: '' },
        { key: 3, label: 'Brand', val: '' },
        { key: 4, label: 'ISBN', val: '' },
      ])
    }
  }, [searchQuery])

  const _handleChange = useCallback(
    (
      _response: any,
      event:
        | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      const inputValue = (event as any).target.value
      console.log('inputValue', inputValue)
      if (inputValue.length < 28) {
        setValue(inputValue)
        onChange(inputValue)
        const searchData: any = { ...searchQuery }
        if (checked) {
          searchData.simple[checked] = inputValue
          setSearchQuery && setSearchQuery(searchData)
        }
      }
    },
    [setValue, onChange, searchQuery, checked, value],
  )

  const searchQueries = (query: any) => {
    const { simple } = query
    const _query = { ...query }
    delete _query.simple

    const data: any[] = Object.entries(_query).map((item, index) => {
      const key = item[0].toLowerCase()
      if (simple[item[0]]) {
        return {
          key: index,
          label: key,
          val: simple[key],
        }
      }

      return {
        key: index,
        label: key,
        val: item[1],
      }
    })

    setChipData(data)
  }

  const [chipData, setChipData] = useState([
    // { key: 0, label: 'Code', val: '' },
    { key: 0, label: 'Name', val: '' },
    { key: 1, label: 'Model', val: '' },
    { key: 2, label: 'Brand', val: '' },
    { key: 3, label: 'ISBN', val: '' },
  ])

  const handleDelete = (chipToDelete: any) => () => {
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete?.key))
    const deleteData: any = { ...searchQuery }
    const deleteLabel = chipToDelete.label.toLowerCase()
    const selectedOption = checked?.toLowerCase()
    const checkSimple = deleteLabel === selectedOption
    deleteData[deleteLabel] = ''

    const simpleSearch: any = { ...deleteData }

    if (chipToDelete.val === value && checkSimple) {
      setValue('')
    }

    chipData.forEach(chip => {
      const chipLabel = chip.label.toLowerCase()
      if (chip.key !== chipToDelete?.key && chip.val === simpleSearch.simple[chipLabel]) {
        simpleSearch[chip.label] = chip.val
      }
    })

    setSearchQuery && setSearchQuery(deleteData)
    setSearchValue(deleteData)

    if (chipToDelete.val !== value && deleteLabel !== selectedOption && value) {
      onSearchModal && onSearchModal(simpleSearch)
    } else {
      if ((chipToDelete.val === value && checkSimple) || !value) {
        deleteData.simple = {}
      }
      onSearchModal && onSearchModal(deleteData)
    }

    setError(false)
    setErrorMessage('')
  }

  const handleOnCleanInput = () => {
    setValue('')
    setError(false)
    if (checked) {
      const deleteData: any = { ...searchQuery }
      deleteData.simple = {}
      deleteData[checked] = ''
      setSearchQuery && setSearchQuery(deleteData)
      onSearchModal && onSearchModal(deleteData)
      searchQueries(deleteData)
    }
  }

  const onOptionChange = (option: string) => {
    if (checked && setSearchQuery) {
      const searchData: any = { ...searchQuery }
      const previousValue = searchData.simple[checked]
      delete searchData.simple[checked]
      searchData[option] = ''
      searchData.simple[option] = previousValue
      setSearchQuery(searchData)
    }
    setChecked(option)
  }

  return (
    <SearchStyle>
      <div style={{ maxWidth: '520px' }}>
        {error && (
          <div style={{ marginBottom: '10px' }}>
            <AlertError fullWidth>{errorMessage} </AlertError>
          </div>
        )}
        <SearchBar
          label="Search Supplier"
          value={value}
          withSearchOptions
          searchOptions={SearchOptions}
          selectedOption={checked}
          onSearchOptionChange={onOptionChange}
          cleanInputIcon
          disabled={false}
          autoFocus={autoFocus}
          onCleanInputIconClick={handleOnCleanInput}
          onSearchIconClick={() => {
            if (error) {
              return
            }
            if (value && onSearch) {
              if (checked && value) {
                searchValue[checked] = ''
                if (searchQuery) {
                  searchQuery[checked] = ''
                }
                const searchData: any = { ...searchQuery }
                searchQueries(searchData)
              }
              onSearch()
            }
            setError(false)
            setErrorMessage('')
          }}
          onChange={_handleChange}
          placeholder="Search.."
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: 5,
          marginTop: '20px',
        }}
      >
        {chipData
          ?.filter(i => i.val)
          .map(data => {
            return (
              <div
                key={data.key}
                style={{
                  marginBottom: 5,
                  marginRight: 5,
                }}
              >
                <div className="chip-container">
                  <span>{`${data.label === 'isbn' ? upperCase(data.label) : startCase(camelCase(data.label))}: ${
                    data.val
                  }`}</span>
                  <div className="clear" onClick={data.label === 'React' ? undefined : handleDelete(data)}>
                    <IoMdCloseCircle size={20} />
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      {loading && false && (
        <div className="advanceSearch">
          <span onClick={openModal}>Advanced Search</span>
        </div>
      )}
      <AdvanceSearchModal
        onSubmit={searchQueries}
        onSearch={() => {
          setError(false)
          setErrorMessage('')
          onSearchModal && onSearchModal(searchQuery)
        }}
        setValue={setValue}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        open={open}
        setOpen={setOpen}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />

      {!hideResults && (
        <DisplayResults>
          Showing {!currentTotal ? 0 : currentTotal === 0 ? currentTotal : 1} to {rowsPerPage || 0} of {total || 0}{' '}
          entries {total && total > 0 && currentTotal === 0 && `(filtered from ${total || 0} total entries)`}
        </DisplayResults>
      )}
    </SearchStyle>
  )
}

export default SearchBox
