import { gql } from '@apollo/client'

export const GET_SHOPS = gql`
  query GET_SHOPS($name: String!, $page: Int!, $totalPage: Int!, $model: String!, $code: String!, $brand: String!) {
    products(
      filter: {
        name: { matches: $name }
        productCode: $code
        modelName: { equalTo: $model }
        manufacturer: { matches: $brand }
      }
      first: $totalPage
      skip: $page
    ) {
      totalCount
      nodes {
        id
        name
        manufacturer {
          name
        }
        modelName
        offers {
          nodes {
            id
            name
            merchant {
              name
              merchantId
            }
            images {
              nodes {
                original
              }
            }
            currentPrice {
              amount
              currency {
                alphabeticCode
              }
            }
            clickURL
          }
        }
      }
    }
  }
`

export const LIST_SHOPS = gql`
  query {
    offers(filter: { name: { matches: "Apple Iphone 12 phone" } }) {
      nodes {
        name
        product {
          globalTradeItemNumber
          manufacturer {
            name
          }
          modelName
        }
        clickURL
      }
    }
  }
`

export const GET_MERCHANTS = gql`
query {query :merchants}`