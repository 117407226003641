import styled, { keyframes, css } from 'styled-components'

interface Props {
  isLoading?: boolean
}

const SpinAnimation = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`

const Button = styled.button<Props>`
  padding: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 34px;
  &:first-child {
    margin-left: 0;
  }
  &:focus {
    outline: 0;
  }
  animation: ${props =>
    props.isLoading
      ? css`
          animation: ${SpinAnimation} 2s infinite linear;
        `
      : ''};
`

export const GreenButton = styled(Button)`
  background-color: #66cc33;
  /* border: none !important; */
  &:hover {
    background-color: #78cd51;
  }
  &:focus {
    border: none;
  }
`
export const RedButton = styled(Button)`
  background-color: #ff6666 !important;
`
export const BlueButton = styled(Button)`
  background-color: #66ccff;
`
export const GreyButton = styled(Button)`
  color: #000000;
  background-color: #dedede;
`
