/* eslint-disable no-unused-expressions */
import React, { useCallback, useState } from 'react'
import { Dialog, TextField } from '@procurenetworks/procure-component-library'
import { FaTimes } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import _ from 'lodash'

import {
  AlertError,
  ButtonClose,
  AdvanceSearch,
  CancelButton,
  FooterButton,
  SearchButton,
  Container,
} from './index-styles'

interface Props {
  open: boolean
  setOpen: (data: boolean) => void
  onSubmit?: (data: string) => void
  searchQuery: any
  searchValue: any
  setSearchQuery?: (data: any) => void
  setSearchValue?: (data: any) => void
  set?: (data: any) => void
  onSearch: (data?: string) => void
  setValue: (data: string) => void
}

const AdvanceSearchModal: React.FC<Props> = ({
  open,
  setOpen,
  onSubmit,
  searchQuery,
  setSearchValue,
  searchValue,
  onSearch,
  setValue,
}) => {
  const [validateError, setValidateError] = useState<{ field?: string; message?: string }>({})

  const handleClose = () => {
    setOpen(false)
    setValidateError({})
  }

  const handleOnSearch = () => {
    if (!_.isEmpty(validateError)) {
      return
    }

    Object.keys(searchValue).map(item => {
      searchQuery[item] = searchValue[item]
      if (searchQuery[item]) {
        if (item === Object.keys(searchQuery.simple)[0]) {
          setValue('')
        }
        delete searchQuery.simple[item]
      }
      return null
    })

    onSubmit && onSubmit(searchQuery)
    if (searchQuery.code || searchQuery.name || searchQuery.model || searchQuery.brand || searchQuery.isbn) {
      setOpen(false)
    } else {
      setValidateError({
        field: 'name',
        message: 'Please add any field.',
      })
      return false
    }
    onSearch && onSearch()
  }

  const _handleCloseAlert = useCallback(() => {
    setValidateError({})
  }, [setValidateError])

  const handleInputChange = (event: any) => {
    setValidateError({})
    if (/^$|^\S+.*/.test(event.target.value)) {
      const { name, value, id } = event.target
      let updateSearchValue = {}
      if (id === 'isbn' || (name === 'isbn' && searchValue.code)) {
        updateSearchValue = {
          code: '',
          [name || id]: value,
        }
      } else if (id === 'code' || (name === 'code' && searchValue.isbn)) {
        updateSearchValue = {
          isbn: '',
          [name || id]: value,
        }
      } else {
        updateSearchValue = {
          [name || id]: value,
        }
      }

      setSearchValue &&
        setSearchValue((values: any) => ({
          ...values,
          ...updateSearchValue,
        }))
    }
  }

  const body = (
    <>
      {!_.isEmpty(validateError) && (
        <div style={{ marginBottom: '10px' }}>
          <AlertError fullWidth>
            {validateError.message}{' '}
            <ButtonClose onClick={_handleCloseAlert}>
              <FaTimes />
            </ButtonClose>
          </AlertError>
        </div>
      )}

      <AdvanceSearch>
        <TextField label="Product Name" value={searchValue.name} name="name" onChange={handleInputChange} id="name" />
      </AdvanceSearch>
      <AdvanceSearch>
        <TextField label="ISBN" value={searchValue.isbn} name="isbn" onChange={handleInputChange} id="isbn" />
      </AdvanceSearch>
      <AdvanceSearch>
        <TextField
          label="UPC / Product Code"
          value={searchValue.code}
          name="code"
          onChange={handleInputChange}
          id="code"
        />
      </AdvanceSearch>
      <AdvanceSearch>
        <TextField
          label="Model / Model Number"
          value={searchValue.model}
          name="model"
          onChange={handleInputChange}
          id="model"
        />
      </AdvanceSearch>
      <AdvanceSearch>
        <TextField label="Brand" value={searchValue.brand} name="brand" onChange={handleInputChange} id="brand" />
      </AdvanceSearch>
      <FooterButton>
        <CancelButton title="Cancel" onClick={handleClose} />
        <SearchButton title="Search" disabled={!_.isEmpty(validateError)} onClick={handleOnSearch} />
      </FooterButton>
    </>
  )

  return (
    <Dialog open={open} id="advanced-search">
      <Container>
        <div className="header">
          <span>Advanced Search</span>
          <button onClick={handleClose} type="button">
            <IoMdClose size={24} />
          </button>
        </div>
        <div className="body">{body}</div>
      </Container>
    </Dialog>
  )
}

export default AdvanceSearchModal
