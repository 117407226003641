import * as Sentry from '@sentry/react'
import { Action, Location } from '@sentry/react/dist/types'
import { BrowserTracing } from '@sentry/tracing'
import config from './common/config'
import history from './router/history'
import { httpError5XXCodes } from './utils/httpErrorCodes'

const hashHistoryV4Compat = {
  ...history,
  listen: (callback: (location: Location, action: Action) => void) => {
    return history.listen(update => {
      callback(update.location, update.action)
    })
  },
}

class BugReportingService {
  static initializeSentryClient() {
    Sentry.init({
      dsn: config.sentryDSN,
      integrations: [
        new BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(hashHistoryV4Compat) }),
      ],
      tracesSampleRate: 1,
      maxBreadcrumbs: 50,
      attachStacktrace: true,
      beforeBreadcrumb: breadcrumb => {
        if (breadcrumb.category === 'console') {
          return null
        }

        return breadcrumb
      },
    })
  }

  /**
   * Report 5XX error to sentry.
   * @param {number} responseCode API http response code.
   * @param {Error} errorStack Error stacktrace detail.
   */
  static apiErrorResponseHandler(responseCode: number, errorStack: any) {
    if (httpError5XXCodes.has(responseCode)) {
      Sentry.captureException(errorStack)
    }
  }

  static createReduxEnhancer() {
    return Sentry.createReduxEnhancer({
      configureScopeWithState(scope, state) {
        const currentUser = state.userSlice.user && state.userSlice.user.currentUser
        if (currentUser) {
          const { id: userId, tenantId } = currentUser

          const currentUserInfo = {
            userId,
            tenantId,
          }

          scope.setTags(currentUserInfo)
        }
      },
      stateTransformer(state) {
        const currentUser = state.userSlice.user && state.userSlice.user.currentUser
        if (currentUser) {
          const { id: userId, tenantId, workspacePermissions } = currentUser
          return {
            userId,
            tenantId,
            workspacePermissions,
          }
        }
      },
    })
  }
}

export default BugReportingService
