import React, { FC } from 'react'
import styled from 'styled-components'

import {
  BounceLoader,
  CircularContainer,
  FirstBall,
  ForthBall,
  Loader,
  LoaderWrapper,
  SecondBall,
  ThirdBall,
} from './styles'

interface Props {
  active: boolean
}

const LoaderStyle = styled.div`
  width: 100%;
  text-align: center;
  height: calc(100vh - 61px);
  align-items: center;
  display: inline-flex;
  .loader {
    display: inline-block;
    position: static;
    margin: 0 auto;
  }
`

export const CustomLoader: FC<Props> = ({ active }) => {
  return active ? (
    <LoaderWrapper className="loader">
      <BounceLoader>
        <Loader>
          <CircularContainer>
            <FirstBall />
          </CircularContainer>
          <CircularContainer>
            <SecondBall />
          </CircularContainer>
          <CircularContainer>
            <ThirdBall />
          </CircularContainer>
          <CircularContainer>
            <ForthBall />
          </CircularContainer>
        </Loader>
      </BounceLoader>
    </LoaderWrapper>
  ) : null
}

export const MainLoader: FC<Props> = ({ active }) => {
  return (
    <LoaderStyle>
      <CustomLoader active={active} />
    </LoaderStyle>
  )
}

export default {
  CustomLoader,
  MainLoader,
}
