/* stylelint-disable font-family-no-missing-generic-family-keyword */
import React from 'react'
import { Button } from '@procurenetworks/procure-component-library'
import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  border-radius: 4px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    span {
      color: #66ccff;
      font-size: 20px;
      font-weight: 700;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    button {
      background: none;
      outline: none;
      border: none;
    }
  }
  .body {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 25px;

    @media (max-width: 400px) {
      justify-content: unset;
    }

    @media (max-width: 480px) {
      padding: 18px !important;
    }
  }
`

export const SearchStyle = styled.div`
  width: 100%;
  .chip-container {
    background-color: transparent;
    border: 1px solid #bdbdbd;
    border-radius: 20px;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .clear {
      cursor: pointer;
      background: none;
      outline: none;
      border: none;
      color: #ddd;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 10px;
      margin-right: -7px;
    }
  }

  input {
    height: 34px;
    color: #777;
    font-size: 14px;
    font-weight: bold;

    &::placeholder {
      font-weight: normal;
    }
  }
  .advanceSearch {
    color: #4880ff;
    padding-top: 7px;

    span {
      cursor: pointer;
    }
  }
  form {
    @media only screen and (max-width: 768px) {
      height: 100px;
    }
  }
`
export const SearchLabel = styled.div`
  font-family: Helvetica;
  font-weight: 600;
  color: #797979;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 10px;
`

export const DisplayResults = styled.p`
  width: 100%;
  margin: 10px 0 15px;
  font-size: 13px;
  color: #797979;
`

export const SearchBoxInline = styled.div`
  display: inline-flex;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgb(148 146 146 / 75%);
  border-radius: 7px;
  .search-wrap {
    background-color: #4880ff;
    height: 35px !important;
    width: 35px !important;
    padding: 0 !important;
    border-radius: 5px;
    position: absolute;
    right: 5px;
    top: 5px;
    svg {
      color: #fff;
    }
  }
`
export const DropDownSearch = styled.div`
  position: relative;
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    display: flex;
    align-items: center;
  }
  span {
    /* padding: 11px; */
    font-size: 13px;
    @media only screen and (max-width: 400px) {
      font-size: 11px;
    }
  }
  .search-box-input {
    height: 45px !important;
    border: none;
    outline: none;
    padding: 5px 40px 5px 10px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    @media only screen and (max-width: 360px) {
      font-size: 12px !important;
      padding: 5px 40px 5px 5px;
    }
  }
  .up-down-arrow {
    .supply-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      width: 211px;
      @media only screen and (max-width: 400px) {
        width: 148px;
      }
      @media only screen and (max-width: 360px) {
        padding: 10px 5px;
      }
    }
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16px;
    cursor: pointer;
    /* color: #4880ff; */
    position: relative;
    &:after {
      position: absolute;
      content: '';
      height: 30px;
      background-color: #4880ff6b;
      width: 1px;
      margin-left: 10px;
      /* top: 2px; */
      right: 0;
    }
  }
`

export const CustomDropDown = styled.ul`
  position: absolute;
  z-index: 999;
  margin: 0px;
  padding: 5px 0px;
  width: 50%;
  top: 50px;
  box-shadow: 0px 1px 5px 0px rgb(148 146 146 / 75%);
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  div {
    cursor: initial !important;
  }
  @media only screen and (max-width: 767px) {
    top: 58px;
  }
  li {
    list-style-type: none;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    div {
      cursor: pointer !important;
    }
    span {
      border: none;
    }
  }
  .search-box-dropdown {
    display: flex;
  }

  .first-opt-icon {
    svg {
      color: #45a245;
    }
  }

  .second-opt-icon {
    svg {
      color: #b1b133;
    }
  }
  .third-opt-icon {
    svg {
      color: #f06802;
    }
  }
  .first-checkBox,
  .third-checkBox {
    input {
      height: 20px !important;
      position: relative;
      right: -36px;
    }
  }
  .second-checkBox {
    input {
      height: 20px !important;
      position: relative;
      right: -74px;
    }
  }
`

export const SearchIconStyle = styled.div`
  border: 1px solid #ccc;
  border-left: none;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  color: #666;
  cursor: pointer;

  .viewSearchIcon {
    .MuiOutlinedInput-root {
      border-radius: 4px 0 0 4px;
    }
  }
`
export const ListBox = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 9px;
  width: 100%;
  z-index: 999999999;
  position: relative;
  cursor: pointer !important;
  input {
    width: unset !important;
  }
  &:hover {
    background-color: rgba(72, 128, 255, 0.2);
  }
  &.active {
    background-color: #4880ff;
    p {
      color: #fff;
    }
  }
  p {
    font-size: 16px;
    margin: 0px;
    margin-left: 15px;
    flex: 1;
  }
  svg {
    font-size: 25px;
  }
  .container-wrap {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }
  .container-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #bbbbbb !important;
    border-radius: 5px;
  }
  .container-wrap:hover input ~ .checkmark {
    background-color: #fff;
  }
  .container-wrap input:checked ~ .checkmark {
    background-color: #fff;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container-wrap input:checked ~ .checkmark:after {
    display: block;
  }
  .container-wrap .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid #4880ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

export const AdvanceSearch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  width: 550px;
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
    width: 250px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  .AdvanceSearchInput {
    /* margin-bottom: 10px; */
    outline: none;
    width: 25rem;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    height: 39px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .MuiInputLabel-outlined {
    background: #fff !important;
    font-size: 16px;
    padding: 0px 4px !important;
    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
`

export const AdvanceSearchContent = styled.div`
  /* margin-right: 10rem; */
  margin-bottom: 0px;
  font-size: 15px;
  width: 200px;
  flex: 1;
  @media only screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`
export const FooterButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  @media only screen and (max-width: 340px) {
    margin-top: 30px;
  }
`

export const CustomSearchButton = styled.div`
  button {
    &:disabled {
      opacity: 0.5;
      color: #fff;
    }
  }
`

interface AlertErrorProps {
  fullWidth: boolean
}

export const AlertError = styled.div<AlertErrorProps>`
  box-sizing: border-box;
  width: ${props => (props?.fullWidth ? '100%' : '50%')};
  margin: 0 auto 30px;
  padding: 10px 15px;
  border-radius: 4px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #f2dede;
  position: relative;
  font-size: 13px;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 10px;
    padding: 10px 10px;
  }
`

export const SuccessAlert = styled.div`
  box-sizing: border-box;
  width: 49%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 4px;
  color: #49908e;
  background-color: #dbf1d9;
  border-color: #dbf1d9;
  position: relative;
  font-size: 13px;
`

export const ButtonClose = styled.a`
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  opacity: 0.2;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const CustomButton = styled.div`
  button {
    margin-right: 10px;
  }
`

interface ActionType {
  title?: string
  icon?: string
  onClick: (event?: any) => void
  isLoading?: boolean
  disabled?: boolean
  isSubmitting?: boolean
  type?: string
}

export const CancelButton = ({ title, onClick, icon, isLoading, disabled, isSubmitting }: ActionType) => {
  return (
    <CustomButton>
      <Button onClick={onClick}>
        {title}
        {icon}
        {isLoading}
        {disabled}
        {isSubmitting}
      </Button>
    </CustomButton>
  )
}

export const SearchButton = ({ title, onClick, icon, isLoading, disabled, isSubmitting }: ActionType) => {
  return (
    <CustomSearchButton>
      <Button onClick={onClick} disabled={disabled} theme="success">
        {title}
        {icon}
        {isLoading}
        {isSubmitting}
      </Button>
    </CustomSearchButton>
  )
}
