import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AffiliateLogoConfigType } from '../../common/types/affiliate-logo-config'
import { RootState } from '../rootReducer'
import { affiliateController } from '../../common/clients'

// get all affiliates logo config
export const getAllAffiliateLogoConfig = createAsyncThunk(
  'affiliates-logo-config/all',
  async ({ admin, name }: any) => {
    return await affiliateController.getAffiliatesConfiguration(name, admin)
  },
)

// get detail affiliate by id
export const getAffiliateLogoConfigById = createAsyncThunk('affiliate-logo-config/get-by-id', async (id: string) => {
  return await affiliateController.getDetailAffiliateLogoConfig(id)
})

// update affiliate by id
export const updateAffiliateLogoConfigWithId = createAsyncThunk(
  'affiliate-logo-config/update',
  async (affiliate: AffiliateLogoConfigType) => {
    if (affiliate._id) {
      return await affiliateController.updateAffiliateLogoConfig(affiliate._id, affiliate)
    }
  },
)

// create affiliate logo config
export const createAffiliateLogoConfig = createAsyncThunk(
  'affiliate-logo-config/create',
  async (affiliate: AffiliateLogoConfigType) => {
    return await affiliateController.createAffiliateLogoConfig(affiliate)
  },
)

// delete affiliate logo config
export const deleteAffiliateLogoConfigWithId = createAsyncThunk('affiliate-logo-config/delete', async (id: string) => {
  return await affiliateController.deleteAffiliateLogoConfig(id)
})

type CurrentState = {
  affiliate: AffiliateLogoConfigType | null
  affiliates: AffiliateLogoConfigType[]
  affilatesLength: number
  error: any | null
  loading: boolean
  isLength: boolean
  newAffiliate: AffiliateLogoConfigType | null
  isUpdated: boolean
  isCreated: boolean
  isDeleted: boolean
  notification: {
    status: null | string
    message: string
  }
}

const initialState = {
  affiliate: null,
  affiliates: [],
  affilatesLength: 0,
  error: {},
  isLength: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  loading: false,
  newAffiliate: null,
  notification: {
    message: '',
    status: null,
  },
} as CurrentState

const affiliateLogoSlice = createSlice({
  initialState,
  name: 'affiliateLogoConfig',
  extraReducers: (builder: ActionReducerMapBuilder<CurrentState>) => {
    // all affiliate
    builder.addCase(getAllAffiliateLogoConfig.pending, state => {
      state.loading = true
      state.notification = initialState.notification
    })
    builder.addCase(getAllAffiliateLogoConfig.fulfilled, (state, action) => {
      state.affiliates = action.payload
      if (!state.isLength || state.affilatesLength < action.payload.length) {
        state.affilatesLength = action.payload.length
        state.isLength = true
      }
      state.error = {}
      state.loading = false
    })
    builder.addCase(getAllAffiliateLogoConfig.rejected, (state, action) => {
      state.affiliates = []
      state.error = action.error
      state.loading = false
    })
    // get affiliate by id
    builder.addCase(getAffiliateLogoConfigById.pending, state => {
      state.loading = true
      state.notification = initialState.notification
    })
    builder.addCase(getAffiliateLogoConfigById.fulfilled, (state, action) => {
      state.loading = false
      state.error = {}
      state.affiliate = action.payload
    })
    builder.addCase(getAffiliateLogoConfigById.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.affiliate = null
    })
    // update affiliate logo config by id
    builder.addCase(updateAffiliateLogoConfigWithId.pending, state => {
      state.loading = true
      state.notification = {
        message: '',
        status: null,
      }
    })
    builder.addCase(updateAffiliateLogoConfigWithId.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.affiliate = action.payload
      state.isUpdated = true
      state.notification = {
        message: 'Updated affiliate config successfully!',
        status: 'success',
      }
    })
    builder.addCase(updateAffiliateLogoConfigWithId.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.affiliate = null
      state.isUpdated = false
      state.notification = {
        message: 'Updated affiliate config failed!',
        status: 'error',
      }
    })
    // create affiliate logo config
    builder.addCase(createAffiliateLogoConfig.pending, state => {
      state.loading = true
      state.notification = {
        message: '',
        status: null,
      }
    })
    builder.addCase(createAffiliateLogoConfig.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.newAffiliate = action.payload
      state.isCreated = true
      state.notification = {
        message: 'Created affiliate config successfully!',
        status: 'success',
      }
    })
    builder.addCase(createAffiliateLogoConfig.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
      state.newAffiliate = null
      state.isCreated = false
      state.notification = {
        message: 'Created affiliate config failed!',
        status: 'error',
      }
    })
    // delete affiliate logo by id
    builder.addCase(deleteAffiliateLogoConfigWithId.pending, state => {
      state.loading = true
      state.notification = {
        message: '',
        status: null,
      }
    })
    builder.addCase(deleteAffiliateLogoConfigWithId.fulfilled, state => {
      state.loading = false
      state.isDeleted = true
      state.error = null
      state.notification = {
        message: 'Deleted affiliate config successfully!',
        status: 'success',
      }
    })
    builder.addCase(deleteAffiliateLogoConfigWithId.rejected, (state, action) => {
      state.loading = false
      state.isDeleted = false
      state.error = action.error
      state.notification = {
        message: 'Deleted affiliate config failed!',
        status: 'error',
      }
    })
  },
  reducers: {},
})

export const affiliateLogoSelector = (state: RootState) => state.affiliateLogoSlice

export default affiliateLogoSlice.reducer
