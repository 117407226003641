import styled from 'styled-components'

export const AffiliateMerchantStyle = styled.div`
  /* width: 55%; */
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 24px;
  @media (max-width: 320px) {
    padding: 12px;
  }
`

export const Title = styled.h3`
  font-size: 13px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  color: #797979;
`

export const ShopItems = styled.div`
  width: 100%;
  .close_icon {
    position: absolute;
    top: 15px;
    font-size: 20px;
    right: 15px;
    cursor: pointer;
  }
  .shopCard {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    @media (max-width: 1024px) {
      justify-content: center;
    }
  }
  .Cards {
    padding: 24px;
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    @media (max-width: 1024px) {
      flex-grow: 1;
      width: 50%;
      flex-basis: 50%;
      max-width: 50%;
    }
    @media (max-width: 600px) {
      flex-grow: 1;
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`
export const ShopItem = styled.div`
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 2px solid #fff;
  /* background-color: red; */
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .closeButton {
    text-align: left;
    margin-left: auto;
    display: block;
    border: none;
    background: none;
  }
`

export const ShopLogo = styled.a`
  /* display: inline-block; */
  width: 100%;
  height: 180px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: 376px) {
    height: 100px;
  }
  @media (min-width: 425px) {
    height: 125px;
  }
  @media (min-width: 767px) {
    height: 124px;
  }
  @media (min-width: 991px) {
    height: 155px;
  }
  @media (min-width: 1024px) {
    height: 93px;
  }
  @media (min-width: 1440px) {
    height: 162px;
  }
`
export const ShopLogoImage = styled.img`
  object-fit: contain;
`

export const NoImageFound = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 10px auto;
`

export const ProductImage = styled.a`
  display: inline-block;
  width: 100%;
  height: 180px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  @media (min-width: 425px) {
    height: 125px;
  }
  @media (min-width: 767px) {
    height: 182px;
  }
  @media (min-width: 991px) {
    height: 155px;
  }
  @media (min-width: 1024px) {
    height: 164px;
  }
  @media (min-width: 1440px) {
    height: 268px;
  }
`

export const ShopTitle = styled.a`
  text-align: center;
  font-weight: normal;
  line-height: 1.5;
  font-size: 14px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  margin: 10px 0 5px;
  &:hover {
    cursor: pointer;
    color: #66ccff;
  }
`

export const SaleLabel = styled.p`
  font-size: 13px;
  text-align: center;
  margin: 0;
  color: #888;
`

export const AmountLabel = styled.h3`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 0;
  color: #000;
`

export const MerchantLabel = styled.p`
  text-align: center;
  font-size: 14px;
  margin: 0;
  line-height: 19px;
  text-transform: uppercase;
`

export const CompanyLogo = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  span {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
`

export const NoItemFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
