import React from 'react'
import * as Sentry from "@sentry/react";
import {Route} from "react-router-dom";

const SentryRoute = Sentry.withSentryRouting(Route)

interface Props {
  layout: any,
  component: any,
  exact?: boolean,
  path: string
}

function RouteWithLayout(props: Props) {

  const { layout, component, ...rest } = props

  return (
    <SentryRoute {...rest} render={(props) =>
      React.createElement( layout, props, React.createElement(component, props))
    }/>
  );
}

export default RouteWithLayout
