import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { History } from 'history'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllAffiliateLogoConfig, affiliateLogoSelector } from '../../store/slices/affiliate-logo-slice'
import { GreenButton } from '../common/button'
import AffiliateTableComponent from './affiliate-table'
import { AffiliatesListStyle, TitlePage } from './affiliates-list-styles'
import Notification from '../common/notification'
import { userSelector } from 'src/store/slices/user-slice'
import PageTitle from '../PageTitle'
import { PAGE_TITLE } from 'src/common/constants'

interface Props {
  data?: any
  history: History
}

const AffiliatesList: React.FC<RouteComponentProps<any, {}, Props>> = ({ history }) => {
  const dispatch = useDispatch()
  const affiliateSelector = useSelector(affiliateLogoSelector)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [message, setMessage] = useState<string>('')
  const [status, setStatus] = useState<string | undefined>()
  const userDetail = useSelector(userSelector)
  const isSuperAdmin = userDetail.user?.currentUser.isSuperAdmin

  useEffect(() => {
    dispatch(getAllAffiliateLogoConfig({ admin: true, name: search }))
  }, [dispatch, search])

  useEffect(() => {
    if (affiliateSelector.notification.status) {
      setMessage(affiliateSelector.notification.message)
      setStatus(affiliateSelector.notification.status)
      setOpen(true)
    }
    if (!affiliateSelector.notification.status) {
      setMessage('')
      setStatus('')
      setOpen(false)
    }
  }, [affiliateSelector.notification, setMessage, setOpen, setStatus])

  const _handleCreateAffiliate = () => history?.push('/admin/affiliate-configuration/add')

  const handleCloseNotification = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const affiliatesData = useCallback(() => {
    const updatedData = affiliateSelector.affiliates?.map((ele: any) =>
      !ele.updated_by ? { ...ele, updated_by: { first_name: '' } } : ele,
    )
    return updatedData
  }, [affiliateSelector])

  return (
    <>
      <AffiliatesListStyle>
        <PageTitle title={PAGE_TITLE.AFFILIATE_CONFIGURATION} />
        <TitlePage>Affiliate Configuration</TitlePage>
        {isSuperAdmin && (
          <GreenButton className="newButton" onClick={_handleCreateAffiliate}>
            New Affiliate Configuration
          </GreenButton>
        )}
        <AffiliateTableComponent
          onChangeSearch={setSearch}
          affiliates={affiliatesData() || []}
          loading={affiliateSelector.loading}
          totalLength={affiliateSelector.affilatesLength}
          persistKey="affiliates_list_table"
        />
      </AffiliatesListStyle>

      {open && (
        <Notification autoHide open={open} message={message} onClose={handleCloseNotification} status={status} />
      )}
    </>
  )
}

export default withRouter(AffiliatesList)
